import axios, { AxiosResponse } from 'axios'

type TGetPriceResponse = {
  data: {
    amount: string,
    base: string
    currency: string
  }
}

type TGetPrice = (
  symbol: string
) => Promise<AxiosResponse<TGetPriceResponse>>

const getPrices: TGetPrice = (
  symbol: string
) => axios.get(`https://api.coinbase.com/v2/prices/${symbol}-usd/spot`)

export default {
  getPrices
}