import { Button } from 'components/common'
import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1172px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 513px;
  gap: 50px;
  align-items: center;
  height: 100%;
`

export const Title = styled.h1`
  font-size: 64px;
  line-height: 72px;
  color: ${props => props.theme.primaryTextColor};
  font-weight: 400;
  margin: 0 0 26px;
`

export const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.additionalTextColor};
  font-weight: 400;
  margin: 0 0 26px;
`

export const ButtonStyled = styled(Button)`
  margin-bottom: 19px;

  svg {
    margin-left: 40px;
  }
`

export const Image = styled.div`
  max-width: 100%;

  svg {
    width: 100%;
  }
`

