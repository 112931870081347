import styled from 'styled-components'

export const Container = styled.div`
  max-width: 342px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100%;

  @media (min-height: 800px) {
    justify-content: center;
  }
`

export const ButtonsContainer = styled.div`
  position: fixed;
  width: 100%;
  padding: 20px 0px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 342px;

  @media (min-height: 800px) {
    position: relative;
    transform: none;
    bottom: auto;
    left: auto;
  }
`

