import styled from 'styled-components'
import Button from '../button'

export const OverlayContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.blankColor};
  padding: 24px 16px 80px;
  left: 0;
  top: 0;
  z-index: 2;
  overflow: scroll;
  color: ${(props) => props.theme.primaryTextColor};
`

export const OverlayHeader = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  position: relative;
`

export const HeaderLogo = styled.img`
  max-width: 123px;
`

export const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  object-fit: cover;
  position: absolute;
  right: 0px;
  top: 8px;
`

export const OverlayContent = styled.div`
  width: 100%;
  text-align: left;
`

export const OverlayTitle = styled.h2`
  margin: 0 0 24px;
  font-size: 24px;
`

export const OverlayButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  padding-bottom: 24px;
  left: 0;
  bottom: 0px;
`

export const ButtonStyled = styled(Button)`
  max-width: 343px;
  width: 100%;
`

export const OverlayParagraph = styled.p`
  font-size: 14px;
  margin: 0 0 24px;
`

export const OverlaySubtitle = styled.h4`
  font-size: 16px;
  margin: 0 0 8px;
  font-weight: 600;
`

export const OverlayList = styled.ul`
  margin: 0 0 24px;
  padding: 0px;
  font-size: 14px;
  list-style: none;
`

export const OverlayListItem = styled.li`
  margin: 0 0 12px;
`

export const OverlayBody = styled.div`
  max-width: 343px;
  margin: 0 auto;
  line-height: 20px;
`
