import { FC } from 'react'
import { Route, Switch, HashRouter } from 'react-router-dom'
import {
  NotFound,
  ClaimPage,
  HomePage,
  CreateLinkPage,
  ShareLinkPage,
  TransferPage,
  GetTransferIdPage
} from 'components/pages'


const AppRouter: FC = () => {
  return <HashRouter>
    <Switch>
      <Route path='/links/:network_name/:token_symbol/:sender/:transfer_id'>
        <TransferPage />
      </Route>

      <Route path='/links/:chain_id/:tx_hash'>
        <GetTransferIdPage />
      </Route>

      <Route path='/create/:chain_id/:token_address'>
        <CreateLinkPage />
      </Route>

      <Route path='/links/:linkId'>
        <ShareLinkPage />
      </Route>

      <Route path='/:token_symbol'><ClaimPage /></Route>
      <Route exact path='/'><HomePage /></Route>
      <Route path='*'><NotFound /></Route>
    </Switch>
  </HashRouter>
}

export default AppRouter