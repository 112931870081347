
import { Dispatch } from 'redux'
import * as actionsLink from '../actions'
import * as asyncActionsToken from '../../token/async-actions'
import { LinkActions } from '../types'
import { TokenActions } from '../../token/types'
import { UserActions } from '../../user/types'
import { RootState, IAppDispatch } from 'data/store'
import { plausibleApi } from 'data/api'
import { defineNetworkName } from 'helpers'
import { getPrices } from '../../user/async-actions'

export default function getData() {
  return async (
    dispatch: Dispatch<LinkActions> & Dispatch<TokenActions> & Dispatch<UserActions> & IAppDispatch,
    getState: () => RootState
  ) => {
    
    try {
      dispatch(actionsLink.setLoading(true))

      const {
        user: {
          chainId
        },
        link: {
          isClaimed,
          expirationTime,
          type
        },
        token: {
          tokenAddress: linkTokenAddress,
        }
      } = getState()

      if (
        type && linkTokenAddress && chainId
      ) {
        await asyncActionsToken.getTokenData(
          type,
          linkTokenAddress,
          chainId,
          dispatch
        )

        await getPrices(dispatch)
      }
      const networkName = defineNetworkName(chainId)

      if (isClaimed) {
        dispatch(actionsLink.setLoading(false))
        plausibleApi.invokeEvent({
          eventName: 'already_claimed',
          data: {
            network: networkName
          }
        })
        return dispatch(actionsLink.setStep('already_claimed'))
      }

      if (Number(expirationTime) < (+new Date() / 1000)) {
        dispatch(actionsLink.setLoading(false))
        plausibleApi.invokeEvent({
          eventName: 'link_expired',
          data: {
            network: defineNetworkName(chainId),
            expiration_time: String(expirationTime)
          }
        })
        return dispatch(actionsLink.setStep('link_expired'))
      }

      dispatch(actionsLink.setLoading(false))
      dispatch(actionsLink.setStep('initial'))
    } catch (
      error: any
    ) {
      console.log(error, error.statusCode)
    }
  }
}
