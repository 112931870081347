
import { Dispatch } from 'redux';
import { LinkActions } from '../types'
import { RootState } from 'data/store'
import * as linkActions from '../actions'
import { UserActions } from '../../user/types'
import { alertError, handleClaimResponseError, defineNetworkName } from 'helpers'
import { AxiosError } from 'axios'
import { plausibleApi } from 'data/api'

export default function claimERC20(
  link: string
) {
  return async (
    dispatch: Dispatch<LinkActions> & Dispatch<UserActions>,
    getState: () => RootState
  ) => {
    dispatch(linkActions.setLoading(true))
    let {
      user: {
        sdk,
        address,
        chainId,
        claimLink
      },
      link: {
        expirationTime
      },
      token: {
        tokenAddress,
        amount
      }
    } = getState()
    if (!chainId) {
      dispatch(linkActions.setLoading(false))
      return alert(`chainId is not provided`)
    }
  
    if (!tokenAddress) {
      dispatch(linkActions.setLoading(false))
      return alert(`tokenAddress is not provided`)
    }

    if (!amount) {
      dispatch(linkActions.setLoading(false))
      return alert(`amount is not provided`)
    }

    if (!expirationTime) {
      dispatch(linkActions.setLoading(false))
      return alert(`expirationTime is not provided`)
    }


    try {
      if (!link) {
        return alertError('Link not provided')
      }
      const res = await claimLink?.redeem(address)
      if (!res) {
        plausibleApi.invokeEvent({
          eventName: 'claiming_errored',
          data: {
            network: defineNetworkName(chainId),
            error: 'response_not_available'
          }
        })
        return alertError('Some error occured')
      }

      const txHash = res

      if (claimLink && claimLink.transferId) {
        dispatch(linkActions.setTransferId(claimLink.transferId))
      }
      if (txHash) {
        plausibleApi.invokeEvent({
          eventName: 'claiming_started',
          data: {
            network: defineNetworkName(chainId)
          }
        })
        dispatch(linkActions.setHash(txHash))
        dispatch(linkActions.setStep('claiming_process'))
      } else {
        console.log({ txHash })
      }
      
    } catch (error: any | AxiosError) {
      console.log({ error })
      handleClaimResponseError(dispatch, error, chainId)
    }
    dispatch(linkActions.setLoading(false))
  }
}