import { FC } from 'react'
import {
  LogoLoaderContainer,
  LogoLoader
} from './styled-components'
import LinkdropLogo from 'images/linkdrop-logo.png'

const LogoLoaderComponents: FC = () => {
  return <LogoLoaderContainer>
    <LogoLoader src={LinkdropLogo} />
  </LogoLoaderContainer>
}

export default LogoLoaderComponents
