import React, { FC } from 'react'
import { WidgetContainer } from './styled-components'
import TProps from './types'

const Widget: FC<TProps> = ({ children, className }) => {
  return <WidgetContainer className={className}>
    {children}
  </WidgetContainer>
}

export default Widget