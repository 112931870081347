import { LinkState, LinkActions } from './types'
import { Constants } from './constants'

const initialState: LinkState = {
  step: 'short_code_loading',
  loading: false,
  initialized: false,
  chainId: null,
  hash: null,
  type: null,
  title: null,
  error: null,
  wallet: null,
  expirationTime: null,
  isManual: false,
  version: null,
  weiAmount: null,
  isClaimed: false,
  theme: 'dark',
  claimCode: null,
  transferId: null,
  linkId: null,
  transferStatus: null,
  fullLink: null
}

export function linkReducer(
  state: LinkState = initialState,
  action: LinkActions
): LinkState {
    switch (action.type) {
      case Constants.LINK_SET_STEP:
        return {...state, step: action.payload.step }
      case Constants.LINK_SET_CHAIN_ID:
        return {...state, chainId: action.payload.chainId }
      case Constants.LINK_SET_TITLE:
          return {...state, title: action.payload.title }
      case Constants.LINK_SET_INITIALIZED:
        return {...state, initialized: action.payload.initialized }
      case Constants.LINK_SET_FULL_LINK:
        return {...state, fullLink: action.payload.fullLink }
      case Constants.LINK_SET_HASH:
        return {...state, hash: action.payload.hash }
      case Constants.LINK_SET_TYPE:
        return {...state, type: action.payload.type }
      case Constants.LINK_SET_EXPIRATION_TIME:
        return {...state, expirationTime: action.payload.expirationTime }
      case Constants.LINK_SET_WALLET:
        return {...state, wallet: action.payload.wallet }
      case Constants.LINK_SET_TRANSFER_ID:
        return {...state, transferId: action.payload.transferId }
      case Constants.LINK_SET_TRANSFER_STATUS:
        return {...state, transferStatus: action.payload.transferStatus }
      case Constants.LINK_SET_WEI_AMOUNT:
        return {...state, weiAmount: action.payload.weiAmount }
      case Constants.LINK_SET_IS_CLAIMED:
        return {...state, isClaimed: action.payload.isClaimed }
      case Constants.LINK_SET_LOADING:
        return {...state, loading: action.payload.loading }
      case Constants.LINK_SET_ERROR:
        return {...state, error: action.payload.error }
      case Constants.LINK_SET_LINK_ID:
        return {...state, linkId: action.payload.linkId }
      case Constants.LINK_SET_CLAIM_CODE:
        return {...state, claimCode: action.payload.claimCode }
      case Constants.LINK_SET_THEME:
        return {...state, theme: action.payload.theme }
      default:
          return state;
    }
}