import { Dispatch } from 'redux'
import {
  LinkCreateActions,
} from '../types'
import {
  UserActions,
} from '../../user/types'
import {
  LinksActions,
} from '../../links/types'
import {
  alertError,
  checkDepositTxHash,
  defineNetworkName,
  defineAmountRange,
  createLinkObject
} from 'helpers'
import { RootState } from 'data/store'
import * as actionCreateLink from '../actions'
import * as actionUser from '../../user/actions'
import { TCustomSigner } from 'types'
import { plausibleApi } from 'data/api'
import randomBytes from 'randombytes'
import * as actionsLinks from '../../links/actions'

const secure = (
  callback?: (linkId: string) => void
) => {
  return async (
    dispatch: Dispatch<LinkCreateActions> & Dispatch<UserActions> & Dispatch<LinksActions>,
    getState: () => RootState
  ) => {
    dispatch(actionCreateLink.setLoading(true))
    const {
      user: {
        sdk,
        provider,
        chainId,
        address,
        signer
      },
      linkCreate: {
        tokenAddress,
        tokenAmount,
        humanReadableAmount
      },
      token: {
        decimals,
        name,
        image,
        tokenType
      },
      links: {
        links
      }
    } = getState()

    try {

      if (!sdk) {
        return alertError('SDK is not found')
      }
  
      if (!tokenAddress) {
        return alertError('tokenAddress is not defined')
      }

      const claimLink = await sdk.createClaimLink({
        amount: String(tokenAmount),
        chainId: Number(chainId),
        from: address,
        tokenType,
        token: tokenAddress
      })

      if (claimLink && signer) {
        const getRandomBytes = (length: number) => new Uint8Array(randomBytes(length)) 

        const sendTransaction = async ({ to, value, gasLimit, data } : {
          to: string, value: string, gasLimit: number, data: string
        }) => { 
          const tx = await signer.sendTransaction({ to, value, gasLimit: String(gasLimit), data })
          return { hash: tx.hash }
        }

        const signTypedData = (
          domain: any,
          types: any,
          message: Record<string, string | number>
        ) => (signer as TCustomSigner)._signTypedData(domain, types, message)

        let data

        if (tokenType === 'NATIVE') {
          data = await claimLink.deposit({
            sendTransaction,
            getRandomBytes
          })
        } else {
          data = await claimLink.depositWithAuthorization({
            signTypedData,
            getRandomBytes
          })
        }

        if (data) {
          const transferId = data.transferId as string
           if (data.txHash) {
            plausibleApi.invokeEvent({
              eventName: 'signed_to_deposit',
              data: {
                token_amount: defineAmountRange(humanReadableAmount),
                network: defineNetworkName(chainId)
              }
            })
            const txHash = await checkDepositTxHash(claimLink)
            dispatch(actionUser.setClaimLink(claimLink)) 
            dispatch(actionCreateLink.setLoading(false))
            plausibleApi.invokeEvent({
              eventName: 'crypto_deposited',
              data: {
                network: defineNetworkName(chainId),
              }
            })
            const newLink = createLinkObject(
              data.claimUrl,
              data.transferId,
              tokenAddress,
              tokenAmount,
              decimals,
              tokenType,
              name,
              String(image)
            )
            const linksUpdated = [newLink, ...links]

            plausibleApi.invokeEvent({
              eventName: 'link_generated',
              data: {
                network: defineNetworkName(chainId)
              }
            })
      
            dispatch(actionsLinks.setLinks(linksUpdated))
            localStorage && localStorage.setItem('links', JSON.stringify(linksUpdated))
            // dispatch(actionCreateLink.setStep('transaction_sign'))
            if (callback) {
              callback(data.transferId)
            } else {
              dispatch(actionCreateLink.setLoading(false))
            }
          }
        }
      }
      
    } catch (e) {
      const err = e as { code: string }
      if (err.code === "ACTION_REJECTED") {
        alertError('You cancelled the link creation. Please try again')
      } else {
        alertError('Some error occured, please check console for more information')
      }
      console.error({ err })

      dispatch(actionCreateLink.setLoading(false))
    }
  }
}

export default secure

