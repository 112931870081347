const defineRealNetworkName = (chainId: number | null) : string => {
  switch (chainId) {
    case 137: return 'Polygon'
    case 80001: return 'Mumbai'
    case 8453: return 'Base'
    case 84531: return 'BaseGoerli'
    default: return 'Polygon'
  }
}

export default defineRealNetworkName