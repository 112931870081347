import { BigNumber } from 'ethers'

const defineAmountRange = (amount: string) => {
  const amountAsNumber = Number(amount)
  if (amountAsNumber <= 1) {
    return '0_to_1'
  } else if (amountAsNumber <= 10) {
    return '1_to_10'
  } else if (amountAsNumber <= 50) {
    return '10_to_50'
  } else {
    return '50_or_more'
  }
}

export default defineAmountRange
