import { FC, useEffect, ReactElement } from 'react'
import { TTransferStatus } from 'types'
import { Loader } from 'components/common'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import * as linkAsyncActions from 'data/store/reducers/link/async-actions'
import PageConnector from '../page-connector'
import { Container } from './styled-components'

const mapStateToProps = ({
  link: { transferStatus },
  user: { initialized }
}: RootState) => ({
  transferStatus,
  initialized
})

const mapDispatcherToProps = (dispatch: IAppDispatch) => {
  return {
    getTransferId: (
      txHash: string,
      chainId: string,
      callback: (location: string) => void
    ) => dispatch(linkAsyncActions.getTransferId(
      txHash,
      chainId,
      callback
    ))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const GetTransferIdPage: FC<ReduxType> = ({
  getTransferId,
  initialized
}) => {

  const {
    tx_hash,
    chain_id
  } = useParams<{
    chain_id: string
    tx_hash: string
  }>()

  const history = useHistory()

  useEffect(() => {
    if (!initialized) { return }
    getTransferId(
      tx_hash,
      chain_id,
      (location) => history.push(location)
    )
  }, [initialized])

  return <PageConnector urlChainId={chain_id}>
    <Container>
      <Loader />
    </Container>
  </PageConnector>
}


export default connect(mapStateToProps, mapDispatcherToProps)(GetTransferIdPage)