import styled from "styled-components"

export const PoweredBy = styled.a`
  margin: 0;
  font-size: 14px;
  color: ${props => props.theme.additionalTextColor};
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  line-height: 1;
  text-decoration: none;
`

export const PoweredByImage = styled.img`
  max-width: 86px;
  margin-left: 6px;
`