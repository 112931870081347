import { FC } from 'react'
import { Title, Subtitle, Container, ButtonStyled } from 'components/pages/common/styles/styled-components'
import { ButtonsContainer } from '../styled-components'

const ErrorComponent: FC = (props) => {
  return <Container>
    <Title>Seems you're offline</Title>
    <Subtitle>Please, try again</Subtitle>
    <ButtonsContainer>
      <ButtonStyled
        appearance='action'
        onClick={() => window.location.reload()}
      >
        Retry
      </ButtonStyled>
    </ButtonsContainer>
  </Container>
}

export default ErrorComponent