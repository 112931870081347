import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import ButtonOriginal from '../button-original'

export const ButtonLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`

export const Anchor = styled.a`
  text-decoration: none;
  width: 100%;
`

export const ButtonStyled = styled(ButtonOriginal)`
  justify-content: space-between;
  border-radius: 100px;
  font-weight: 500;
  transition: opacity .3s, background-color .3s;

  ${props => props.loading && css`
    justify-content: flex-start;
  `}

  ${props => props.disabled && css`
    color: ${props.theme.secondaryTextColor};
    opacity: .5;
  `}
`