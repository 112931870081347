import styled, { keyframes } from 'styled-components'

const animation = keyframes`
  0% { max-width: 144px; }
  10% { max-width: 168px; }
  90% { max-width: 144px; }
`

export const LogoLoaderContainer = styled.div`
  width: 288px;
  height: 288px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`

export const LogoLoader = styled.img`
  max-width: 144px;
  /* animation: ${animation} 2s ease-in-out infinite; */
`