import React, { FC } from 'react'
import { TProps } from './types'
import { LoaderContainer, LoaderSegment1, LoaderSegment2, LoaderSegment3, LoaderSegment4 } from './styled-components'

const LoadSpinner: FC<TProps> = ({ className = '' }) => {
  const loader = (
    <LoaderContainer className={className} data-testid='loader'>
      <LoaderSegment1 />
      <LoaderSegment2 />
      <LoaderSegment3 />
      <LoaderSegment4 />
    </LoaderContainer>
  )
  return loader
}

export default LoadSpinner
