const defineNetworkId = (chainName?: string) : number => {
  switch (chainName) {
    case 'polygon': return 137
    case 'mumbai': return 80001
    case 'base': return 8453
    case 'baseGoerli': return 84531
    default: return 137
  }
}

export default defineNetworkId