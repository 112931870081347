import applicationOptions from 'configs/application'

const themes = {
  dark: {
    primaryTextColor: applicationOptions.primaryTextColor, // main ccolor of text
    secondaryTextColor: '#0A0B0D', // secondary color of text
    additionalTextColor: '#8A919E', // secondary color of text
    extraTextColor: '#6A6A6A', // secondary color of text
    disabledTextColor: '#DFE0EB', // disabled color of text
    dangerTextColor: '#FF2C2C', // danger color of text
    noteTextColor: '#D4CCFF', // note color of text
    primaryBorderColor: '#32353D',
    secondaryBorderColor: '#000',
    additionalBorderColor: '#272727',
    primaryColor: applicationOptions.backgroundColor, // main color of bg
    secondaryColor: '#363740', // secondary coplor of bg
    blankColor: applicationOptions.backgroundColor, // secondary coplor of bg
    menuItemActive: '#3E4049',
    primaryHighlightColor: applicationOptions.highlightColor,
    loaderPrimaryColor: '#000',
    widgetColor: applicationOptions.backgroundColor,
  
    loaderColor: applicationOptions.highlightColor,
    linkColor: applicationOptions.highlightColor,

    spinnerColor: '#1652F0',


    // buttons
    buttonActionBackgroundColor: applicationOptions.highlightColor,
    buttonActionBackgroundHoverColor: '#357AFF',
    buttonActionBackgroundActiveColor: '#095AF5',

    buttonDisabledBackgroundColor: applicationOptions.highlightColor,

    buttonDefaultBackgroundColor: '#32353D',
    buttonDefaultBackgroundHoverColor: '#32353D',
    buttonDefaultBackgroundActiveColor: '#32353D',
    buttonDefaultBorderHoverColor: '#121212',
    buttonDefaultBorderActiveColor: '#121212',
    buttonDefaultDisabledBorderColor: '#E4E4E4',
    buttonDefaultDisabledTextColor: 'red',

    buttonAdditionalBackgroundColor: '#32353D',
    buttonAdditionalBackgroundHoverColor: '#32353D',
    buttonAdditionalTextHoverColor: '#FFF',
    buttonAdditionalBackgroundActiveColor: '#E4EDFF',
    buttonAdditionalTextActiveColor: '#FFF',
    buttonCopiedColor: '#098551',
    buttonGradient: 'linear-gradient(to right, #00B2FF 0%, #FA00FF 50%, #00B2FF 100%)',

    tagDefaultColor: '#DFE0EB',
    tagErrorColor: '#FF2C2C',
    tagInfoColor: '#2B32EA',
    tagSuccessColor: '#21F142',

    noteDefaultBgColor: '#E4EDFF',
    noteDefaultTextColor: applicationOptions.highlightColor,
    noteAttentionBgColor: '#272727',
    noteAttentionTextColor: '#C3C3C3',

    additionalNoteBgColor: '#1B1B1B',
    additionalNoteTextColor: '#3773F5',

    inputHoverBorderColor: applicationOptions.highlightColor,
    inputDisabledBackgroundColor: '#E4E4E4',
    inputDisabledBorderColor: 'rgba(138, 145, 158, 0.66)',
    inputDisabledTextColor: 'rgba(138, 145, 158, 0.66)',
    inputErrorColor: '#F0616D',
    ethereumLogoColor: '#0EBDCD',
    polygonLogoColor: '#0EBDCD',
    toastBackgroundColor: '#2D2A3D',

    awaitActionStatusUpcoming: '#1E2025',
    awaitActionStatusDone: applicationOptions.highlightColor,
    awaitActionStatusLoading: 'transparent',

    copyContainerTitleColor: '#5B616E'
  },

  light: {
    
  }
}

export default themes