import React from 'react'
import { Provider } from 'react-redux'
import RouterProvider from './router-provider'
import store from 'data/store'
import { Container } from './styled-components'
import { WagmiConfig } from 'wagmi'
import { config } from './connectors/wagmi-config'



class Application extends React.Component {
  render () {
    return <Container>
      <WagmiConfig config={config}>
        <Provider store={store}>
          <RouterProvider />
        </Provider>
      </WagmiConfig>
    </Container>
  }
}
export default Application
