import { FC } from 'react'
import {
  SpinnerContainer,
} from './styled-components'
import { Spinner } from 'components/common'

const SpinnerComponent: FC = () => {
  return <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
}

export default SpinnerComponent
