import { FC } from 'react'
import {
  SpinnerContainer,
  SpinnerItem1,
  SpinnerItem2,
  SpinnerItem3
} from './styled-components'
import TProps from './types'

const SpinnerComponent: FC<TProps> = ({ className }) => {
  return <SpinnerContainer>
    <SpinnerItem1 />
    <SpinnerItem2 />
    <SpinnerItem3 />
  </SpinnerContainer>
}

export default SpinnerComponent