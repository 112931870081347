import styled from 'styled-components'
import { Title, Button, Text, CopyContainer } from 'components/common'

export const Container = styled.div`
  max-width: 342px;
  margin: 0 auto;
`

export const TitleStyled = styled(Title)`
  margin: 0 0 9px;
  align-self: flex-start;
  font-family: 'CoinbaseDisplay', 'Helvetica Neue', sans-serif;
  font-weight: 500;
`

export const CopyContainerStyled = styled(CopyContainer)`
  margin: 0px 0 20px;
  max-width: calc(100vw - 40px);
`

export const ButtonStyled = styled(Button)`
  width: 100%;
  justify-content: center;
  margin-bottom: 16px;
`

export const TextStyled = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin: 0 0 24px;
  color: ${props => props.theme.additionalTextColor};
`

export const AdditionalText = styled(TextStyled)`
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`