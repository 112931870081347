import { FC } from 'react'
import { Image, Title, Subtitle, ButtonStyled } from 'components/pages/common/styles/styled-components'
import Icons from 'icons'

const ErrorComponent: FC = () => {
  return <>
    <Image>
      <Icons.CommonErrorIcon />
    </Image>
    <Title>Something went wrong</Title>
    <Subtitle>Please, try again</Subtitle>
    <ButtonStyled onClick={() => window.location.reload()}>Retry</ButtonStyled>
  </>
}

export default ErrorComponent