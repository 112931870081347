import { TTokenType } from 'types'

type TDefineAmountForText = (
  symbol: string,
  amount: string
) => string

const defineAmountForText: TDefineAmountForText = (symbol, amount) => {
  if (symbol !== '$') {
    return `${amount} ${symbol}`
  }
  return `${symbol}${amount}`
}

export default defineAmountForText