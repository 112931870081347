import styled, { keyframes } from "styled-components"

const bounce = keyframes`
  0%, 20% {
    transform: translate(0,0);
  }
  50% {
    transform: translate(0,-30px);
  }
  80%, 100% {
    transform: translate(0,0);
  };
`

export const SpinnerContainer = styled.div`
  position:relative;
  margin: 0 auto;
  width: 100px;
  display: flex;
  justify-content: space-between;
`

export const SpinnerItem = styled.div`
  height: 16px;
  width: 16px;
  border-radius:50%;
  background: ${props => props.theme.blankColor};
  animation: ${bounce} 3s infinite ease-in-out;
  background: ${props => props.theme.spinnerColor};
`

export const SpinnerItem1 = styled(SpinnerItem)`
  z-index: 1;
`

export const SpinnerItem2 = styled(SpinnerItem)`
  animation-delay: 0.5s;
`

export const SpinnerItem3 = styled(SpinnerItem)`
  animation-delay: 1s;
`
