import { FC } from 'react'
import {
  Image,
  Title
} from 'components/pages/common/styles/styled-components'
import Icons from 'icons'

const ErrorComponent: FC = () => {
  return <>
    <Image>
      <Icons.CommonErrorIcon />
    </Image>
    <Title>Transaction failed</Title>
  </>
}

export default ErrorComponent