import styled from 'styled-components'
import { Button, TokenImage, Title } from 'components/common'
import { PoweredByFooter } from 'components/pages/common'

export const TitleComponent = styled(Title)`
  font-size: 28px;
  font-family: 'CoinbaseDisplay', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.primaryTextColor};
  margin: 0 0 9px;
`

export const Subtitle = styled.h3`
  color: ${props => props.theme.additionalTextColor};
  font-weight: 400;
  margin: 0 0 36px;
  font-size: 16px;
  text-align: center;
`

export const Container = styled.div`
  text-align: center;
  max-width: 343px;
`

export const ScreenButton = styled(Button)`
  max-width: 100%;
  width: 100%;
  margin-bottom: 16px;
  justify-content: center;
`

export const TokenImageContainer = styled(TokenImage)`
  margin: 0 auto 25px;
  background: ${props => props.theme.widgetColor};
`

export const UserAddress = styled.span`
  color: ${props => props.theme.linkColor};
  cursor: pointer;
`

export const PoweredByFooterStyled = styled(PoweredByFooter)`
  align-self: center;
  margin-bottom: 22px;
`