type TDefineSymbolByTokenAddress = (
  tokenAddress: string,
  chain: number | null
) => string

const defineSymbolByTokenAddress: TDefineSymbolByTokenAddress = (
  tokenAddress,
  chain
) => {
  
  if (chain === 80001) {
    switch (tokenAddress.toLowerCase()) {
      case '0x0000000000000000000000000000000000000000':
        return 'matic'
      case '0x0fa8781a83e46826621b3bc094ea2a0212e71b23':
      default:
        return 'usdc'
    }
  }

  if (chain === 8453) {
    switch (tokenAddress.toLowerCase()) {
      case '0x0000000000000000000000000000000000000000':
        return 'eth'
      case '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913':
      default:
        return 'usdc'
    }
  }

  if (chain === 84531) {
    switch (tokenAddress.toLowerCase()) {
      case '0x0000000000000000000000000000000000000000':
        return 'eth'
      case '0xf175520c52418dfe19c8098071a252da48cd1c19':
      default:
        return 'usdc'
    }
  }

  switch (tokenAddress.toLowerCase()) {
    case '0x0000000000000000000000000000000000000000':
      return 'matic'
    case '0x2791bca1f2de4661ed88a30c99a7a9449aa84174':
      return 'usdce'
    case '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359':
    default:
      return 'usdc'
  }

}

export default defineSymbolByTokenAddress