import styled from "styled-components"

export const SpinnerContainer = styled.div`
  width: 240px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 12px;
`
