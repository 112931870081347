import { FC, useEffect } from 'react'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import { 
  TitleComponent,
  ScreenButton,
  Container,
  Subtitle,
  DoneIconContainer,
  PoweredByFooterStyled
} from './styled-components'
import Icons from 'icons'
import { defineExplorerURL } from 'helpers'
import { ButtonsContainer } from '../styled-components'

const mapStateToProps = ({
  user: {
    chainId
  },
  link: {
    hash,
    type
  },
  token: {
    image,
    name,
    decimals,
    amount
  }
}: RootState) => ({
  image,
  name,
  chainId,
  hash,
  type,
  amount,
  decimals
})

type ReduxType = ReturnType<typeof mapStateToProps>

const ClaimingFinished: FC<ReduxType> = ({
  name,
  hash,
  chainId,
  type,
}) => {
  useEffect(() => {
    
  }, [])
  const explorerUrl = chainId && hash ? <ScreenButton
    onClick={() => {
      window.open(`${defineExplorerURL(chainId)}/tx/${hash}`, '_blank')
    }}
    title='View on block explorer'
    appearance='default'
  /> : null


  return <Container>
    <DoneIconContainer>
      <Icons.FinishedIcon />
    </DoneIconContainer>
    <TitleComponent>
      {name} claimed!
    </TitleComponent>
    <Subtitle>
      {name} will appear in your wallet soon.
    </Subtitle>
    <ButtonsContainer>
      <PoweredByFooterStyled />
      {explorerUrl}
    </ButtonsContainer>
  </Container>
}

export default connect(mapStateToProps)(ClaimingFinished)