
import { Dispatch } from 'redux'
import * as actionUser from '../actions'
import { UserActions } from '../types'
import { IAppDispatch } from 'data/store'
import { coinbaseApi } from 'data/api'
import { alertError } from 'helpers'

export default async function getPrices(
  dispatch: Dispatch<UserActions> & IAppDispatch,
) {
  try {
    const picesEth = await coinbaseApi.getPrices('eth')
    const picesMatic = await coinbaseApi.getPrices('matic')
    dispatch(actionUser.setEthPrice(picesEth.data.data.amount))
    dispatch(actionUser.setMaticPrice(picesMatic.data.data.amount))

  } catch (err) {
    alertError('Some error occured. Please check console for more info')
    console.error(err)
  }
}
