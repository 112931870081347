
import { Dispatch } from 'redux'
import * as actionsLink from '../actions'
import { LinkActions } from '../types'
import { TokenActions } from '../../token/types'
import { UserActions } from '../../user/types'
import { RootState, IAppDispatch } from 'data/store'
import { LinkCreateActions } from '../../link-create/types'
import { ethers } from 'ethers'
import { alertError, defineNetworkName, defineSymbolByTokenAddress } from 'helpers'
const { REACT_APP_VERSION_1 } = process.env

export default function getTransferId(
  txHash: string,
  chainId: string,
  callback?: (location: string) => void
) {
  return async (
    dispatch: Dispatch<LinkActions> & Dispatch<LinkCreateActions> & Dispatch<TokenActions> & Dispatch<UserActions> & IAppDispatch,
    getState: () => RootState
  ) => {
    dispatch(actionsLink.setLoading(true))

    const {
      user: {
        sdk,
        provider
      }
    } = getState()

    try {

      const receipt = await provider.getTransactionReceipt(txHash)

      if (!receipt) {
        throw new Error('Transaction receipt not found')
      }

      if (!sdk) {
        throw new Error('SDK is not available. Please check if it was initialized')
      }


      // Find logs related to the specified event
      const { logs }: { logs: { address: string }[] } = receipt
      const depositLogFiltered = logs
        .map(l => {
          try {
            const version = sdk.getVersionFromEscrowContract(l.address) || 0
            return version
          } catch (e) {
            return false
          }
        })
        .filter(v => v > 0)[0]

      if (depositLogFiltered === '1') {
        const linkUpdated = `${REACT_APP_VERSION_1}/#/${window.location.href.split('/#/')[1]}`
        window.location.href = linkUpdated
        return
      }

      const claimLink = await sdk?.retrieveClaimLink({
        txHash, chainId: Number(chainId)
      })

      if (claimLink) {
        const networkName = defineNetworkName(Number(chainId))
        const symbol = defineSymbolByTokenAddress(claimLink.token, Number(chainId))
        callback && callback(`/links/${networkName}/${symbol}/${claimLink.sender}/${claimLink.transferId}`)
      } else {
        throw new Error('No transfer ID detected')
      }
    } catch (
      error: any
    ) {
      console.log(error, error.statusCode)
      alertError('Some error occured. Please check console for more info')
    }
    dispatch(actionsLink.setLoading(false))
  }
}
