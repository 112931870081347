import styled, { css } from 'styled-components'
import { AwaitActionStatus } from './types'

export const Container = styled.ul`
  margin: 0;
  padding: 0;
`

export const AwaitAction = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: ${props => props.theme.primaryTextColor};
  font-size: 16px;
`

export const AwaitActionStatusItem = styled.div<{status: AwaitActionStatus}>`
  margin-right: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;

  ${props => props.status === 'upcoming' && css`
    background-color: ${props.theme.awaitActionStatusUpcoming};
  `}

  ${props => props.status === 'loading' && css`
    background-color: ${props.theme.awaitActionStatusLoading};

    div > div {
      border-color: ${props.theme.primaryHighlightColor} transparent transparent transparent;
    }
  `}

  ${props => props.status === 'done' && css`
    background-color: transparent;
  `}
`