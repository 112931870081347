
import { Dispatch } from 'redux';
import { LinkActions } from '../types'
import * as actionsLink from '../actions'
import axios, { AxiosError } from 'axios'
import { IAppDispatch, RootState } from 'data/store'
import { checkRedeemTxHash, defineNetworkName } from 'helpers'
import { plausibleApi } from 'data/api'

export default function checkTransactionStatus() {
  return async (
    dispatch: Dispatch<LinkActions> & IAppDispatch,
    getState: () => RootState
  ) => {
    try {
      const { link: { hash, transferId, fullLink }, user: { chainId, claimLink }, token: { tokenAddress} } = getState()
      if (!transferId) {
        return alert('No transferId provided')
      }
      if (!tokenAddress) {
        return alert('No tokenAddress provided')
      }
      if (!hash) {
        return alert('No hash provided')
      }
      if (claimLink) {
        await checkRedeemTxHash(claimLink)
        plausibleApi.invokeEvent({
          eventName: 'claiming_success',
          data: {
            network: defineNetworkName(chainId)
          }
        })
        dispatch(actionsLink.setStep('claiming_finished'))
      }
      

   } catch (err: any | AxiosError) {
      
    }
  } 
}
