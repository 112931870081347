
import { Dispatch } from 'redux'
import * as actionCreateLink from '../actions'
import { LinkCreateActions } from '../types'
import { TokenActions } from '../../token/types'
import { UserActions } from '../../user/types'
import { ethers } from 'ethers'
import * as actionAsyncCreateLink from '.'
import { RootState, IAppDispatch } from 'data/store'
import { plausibleApi } from 'data/api'
import { defineNetworkName, alertError, defineAmountRange } from 'helpers'

export default function setTokenAmount(
  amount: string,
  callback?: (linkId: string) => void
) {
  return async (
    dispatch: Dispatch<LinkCreateActions> & Dispatch<TokenActions> & Dispatch<UserActions> & IAppDispatch,
    getState: () => RootState
  ) => {
    const {
      token: {
        decimals
      },
      user: {
        chainId
      }
    } = getState()
    try {

      const amountAtomic = ethers.utils.parseUnits(amount, decimals)
      dispatch(actionCreateLink.setAmount(amountAtomic))
      dispatch(actionCreateLink.setHumanReadableAmount(amount))

      plausibleApi.invokeEvent({
        eventName: 'token_amount_set',
        data: {
          token_amount: defineAmountRange(amount),
          network: defineNetworkName(chainId)
        }
      })

      dispatch(actionAsyncCreateLink.secure(callback))

    } catch (err) {
      alertError('Some error occured. Please check console for more info')
      console.error(err)
    }
  }
}
