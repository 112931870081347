
import { Dispatch } from 'redux'
import * as actionsToken from '../actions'
import { TokenActions } from '../types'
import { LinkCreateActions } from '../../link-create/types'
import { TTokenType } from 'types'
import { nativeTokenAddress } from 'configs/application'
import USDC from 'images/usdc.png'
import ETH from 'images/eth.png'
import PolygonMATIC from 'images/polygon-matic.png'

const getTokenData = async (
  tokenType: TTokenType,
  tokenAddress: string,
  chainId: number,
  dispatch: Dispatch<LinkCreateActions> & Dispatch<TokenActions>
) => {
  try {
    if (tokenAddress !== nativeTokenAddress) {
      dispatch(actionsToken.setName('USDC'))
      dispatch(actionsToken.setImage(USDC))
      dispatch(actionsToken.setDecimals(6))
    } else {
      dispatch(actionsToken.setDecimals(18))
      if (chainId === 8453 || chainId === 84531) {
        dispatch(actionsToken.setName('ETH'))
        dispatch(actionsToken.setImage(ETH))
      } else {
        dispatch(actionsToken.setName('MATIC'))
        dispatch(actionsToken.setImage(PolygonMATIC))
      }
    }
  } catch (
    error: any
  ) {
    console.log(error, error.statusCode)
  }
}

export default getTokenData
