import { action } from 'typesafe-actions'
import { Constants } from './constants'
import { TLinkStep, TTokenType, TTheme, TLinkError, TTransferStatus } from 'types'

export function setStep(step: TLinkStep) {
  return action(Constants.LINK_SET_STEP, { step })
}

export function setLoading(loading: boolean) {
  return action(Constants.LINK_SET_LOADING, { loading })
}

export function setChainId(chainId: number) {
  return action(Constants.LINK_SET_CHAIN_ID, { chainId })
}

export function setTitle (title: string) {
  return action(Constants.LINK_SET_TITLE, { title })
}

export function setHash (hash: string) {
  return action(Constants.LINK_SET_HASH, { hash })
}

export function setTransferId (transferId: string) {
  return action(Constants.LINK_SET_TRANSFER_ID, { transferId })
}

export function setTransferStatus (transferStatus: TTransferStatus) {
  return action(Constants.LINK_SET_TRANSFER_STATUS, { transferStatus })
}

export function setType (type: TTokenType) {
  return action(Constants.LINK_SET_TYPE, { type })
}

export function setWallet (wallet: string) {
  return action(Constants.LINK_SET_WALLET, { wallet })
}

export function setLinkdropMasterAddress (linkdropMasterAddress: string) {
  return action(Constants.LINK_SET_LINKDROP_MASTER_ADDRESS, { linkdropMasterAddress })
}

export function setLinkdropSignerSignature (linkdropSignerSignature: string) {
  return action(Constants.LINK_SET_LINKDROP_SIGNER_SIGNATURE, { linkdropSignerSignature })
}

export function setExpirationTime (expirationTime: string) {
  return action(Constants.LINK_SET_EXPIRATION_TIME, { expirationTime })
}

export function setCampaignId (campaignId: string) {
  return action(Constants.LINK_SET_CAMPAIGN_ID, { campaignId })
}

export function setInitialized (initialized: boolean) {
  return action(Constants.LINK_SET_INITIALIZED, { initialized })
}

export function setWeiAmount (weiAmount: string) {
  return action(Constants.LINK_SET_WEI_AMOUNT, { weiAmount })
}

export function setLinkKey (linkKey: string) {
  return action(Constants.LINK_SET_LINK_KEY, { linkKey })
}

export function setIsClaimed (isClaimed: boolean) {
  return action(Constants.LINK_SET_IS_CLAIMED, { isClaimed })
}

export function setTheme (theme: TTheme) {
  return action(
    Constants.LINK_SET_THEME,
    {
      theme
    }
  )
}

export function setError (error: TLinkError | null) {
  return action(
    Constants.LINK_SET_ERROR,
    {
      error
    }
  )
}

export function setClaimCode (claimCode: string) {
  return action(
    Constants.LINK_SET_CLAIM_CODE,
    {
      claimCode
    }
  )
}

export function setLinkId (linkId: string) {
  return action(
    Constants.LINK_SET_LINK_ID,
    {
      linkId
    }
  )
}


export function setFullLink (fullLink: string) {
  return action(
    Constants.LINK_SET_FULL_LINK,
    {
      fullLink
    }
  )
}

