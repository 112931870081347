import styled, { css } from 'styled-components'
import { Widget } from 'components/common'

export const WidgetStyled = styled(Widget)<{ enableGradient?: boolean }>`
  width: 100%;
  position: relative;
  height: 100%;

  ${props => props.enableGradient && css`
    background: linear-gradient(130.17deg, #687388 -56.32%, #000000 60.12%);
  `}

  ${props => !props.enableGradient && css`
    margin: 0 0 24px;
  `}
`

export const WrapperContainer = styled.div<{finished?: boolean}>`
  padding: 2px;
  width: 100%;
  background-image: conic-gradient(#A524FC, #5DE2F8, #FDCF2F, #A524FC);
  border-radius: 28px;
  margin: 0px 0 24px;
  height: 194px;
`