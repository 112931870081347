export enum Constants {
  LINK_CREATE_SET_STEP = 'LINK_CREATE_SET_STEP',
  LINK_CREATE_SET_LOADING = 'LINK_CREATE_SET_LOADING',
  LINK_CREATE_SET_INITIALIZED = 'LINK_CREATE_SET_INITIALIZED',
  LINK_CREATE_SET_AMOUNT = 'LINK_CREATE_SET_AMOUNT',
  LINK_CREATE_SET_HUMAN_READABLE_AMOUNT = 'LINK_CREATE_SET_HUMAN_READABLE_AMOUNT',
  LINK_CREATE_SET_TOKEN_ADDRESS = 'LINK_CREATE_SET_TOKEN_ADDRESS',
  LINK_CREATE_SET_TRANSFER_ID = 'LINK_CREATE_SET_TRANSFER_ID',
  LINK_CREATE_SET_MAX_TRANSFER_LIMIT = 'LINK_CREATE_SET_MAX_TRANSFER_LIMIT',
  LINK_CREATE_SET_MIN_TRANSFER_LIMIT = 'LINK_CREATE_SET_MIN_TRANSFER_LIMIT'
}