import { FC, useEffect } from 'react'
import { ScreenSubtitle,
  ScreenTitle,
  Container,
  ButtonComponent
} from './styled-components'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { defineExplorerURL } from 'helpers'
import { ButtonsContainer } from '../styled-components'
import * as linkAsyncActions from 'data/store/reducers/link/async-actions'
import { Dispatch } from 'redux'
import {
  SpinnerContainer
} from 'components/pages/common'
import { LinkActions } from 'data/store/reducers/link/types'

const mapStateToProps = ({
  user: { chainId },
  link: { hash }
}: RootState) => ({
  hash,
  chainId
})

const mapDispatcherToProps = (dispatch: IAppDispatch & Dispatch<LinkActions>) => {
  return {
    checkTransactionStatus: () => dispatch(
      linkAsyncActions.checkTransactionStatus()
    )
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const ClaimingProcess: FC<ReduxType> = ({ hash, chainId, checkTransactionStatus }) => {
  useEffect(() => {
    if (!hash) { return }
    checkTransactionStatus()
  }, [])

  const explorerUrl = chainId && hash ? <ButtonComponent
    onClick={() => {
      window.open(`${defineExplorerURL(chainId)}/tx/${hash}`, '_blank')
    }}
    title='View on block explorer'
    appearance='default'
  /> : null
  return <Container>
    <SpinnerContainer />
    <ScreenTitle>Processing Transaction</ScreenTitle>
    <ScreenSubtitle>This may take several minutes to complete. No need to wait here, you can visit your transaction history later check on the status  </ScreenSubtitle>
    <ButtonsContainer>
      {explorerUrl} 
    </ButtonsContainer>
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(ClaimingProcess)
