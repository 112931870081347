import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const TextLink = styled.a`
  display: inline;
  text-decoration: none;
  color: ${props => props.theme.primaryHighlightColor};
  transition: color .3s;
`

export const TextRouterLink = styled(Link)`
  color: ${props => props.theme.primaryHighlightColor};
  text-decoration: none;
`

export const TextButton = styled.span`
  display: inline;
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.theme.primaryHighlightColor};
`
