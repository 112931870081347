import { FC, useEffect, useState } from 'react'
import {
  ScreenButton,
  Container,
  Subtitle,
  TitleComponent,
  UserAddress,
  PoweredByFooterStyled
} from './styled-components'
import { RootState, IAppDispatch } from 'data/store'
import { LinkActions } from 'data/store/reducers/link/types'
import { TokenActions } from 'data/store/reducers/token/types'
import * as linkAsyncActions from 'data/store/reducers/link/async-actions'
import { Dispatch } from 'redux'
import * as linkActions from 'data/store/reducers/link/actions'
import { TLinkStep } from 'types'
import { shortenString, copyToClipboard, defineNetworkName } from 'helpers'
import { ERC20TokenPreview } from 'components/pages/common'
import { connect } from 'react-redux'
import { ethers } from 'ethers'
import { ButtonsContainer } from '../styled-components'
import { plausibleApi } from 'data/api'
import { Toast } from 'components/common'

const mapStateToProps = ({
  token: { name, image, tokenId, amount, tokenAddress, decimals },
  user: {
    address,
    chainId: userChainId,
    userProvider,
    ethPrice,
    maticPrice
  },
  link: { type, loading, chainId }
}: RootState) => ({
  name,
  image,
  tokenType: type,
  tokenId,
  amount,
  decimals,
  loading,
  address,
  userChainId,
  chainId,
  userProvider,
  tokenAddress,
  ethPrice,
  maticPrice
})

const mapDispatcherToProps = (dispatch: Dispatch<LinkActions> & Dispatch<TokenActions> & IAppDispatch) => {
  return {
    claimERC20: (
      link: string
    ) => dispatch(
      linkAsyncActions.claim(link)
    ),
    setStep: (step: TLinkStep) => dispatch(linkActions.setStep(step))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps> 

const InitialScreen: FC<ReduxType> = ({
  name,
  tokenType,
  amount,
  image,
  claimERC20,
  loading,
  address,
  tokenAddress,
  userChainId,
  ethPrice,
  maticPrice,
  decimals
}) => {
  const amountFormatted = ethers.utils.formatUnits(String(amount), decimals)
  const [ copied, setCopied ] = useState(false)

  useEffect(() => {
      plausibleApi.invokeEvent({
        eventName: 'link_opened_wallet',
        data: {
          network: defineNetworkName(userChainId)
        }
      })    
  }, [])

  const defineButton = () => {
    return <ScreenButton
      disabled={
        (tokenType === 'ERC20' && (!amount)) ||
        loading
      }
      loading={loading}
      appearance='action'
      title={`Claim ${name}`}
      onClick={() => {
        return claimERC20(window.location.href)
      }}
    />
  }

  return <Container> 
    <ERC20TokenPreview
      name={name}
      decimals={decimals}
      tokenType={tokenType}
      chainId={userChainId}
      image={image as string}
      amount={String(amountFormatted)}
      tokenAddress={tokenAddress as string}
      ethPrice={ethPrice}
      maticPrice={maticPrice}
    />
    <TitleComponent>Claim {name}</TitleComponent>
    <Subtitle>
      Tap claim {name} to send this {name} to your wallet <UserAddress onClick={
        () => {
          setCopied(true)
          window.setTimeout(() => {
            setCopied(false)
          }, 4000)
          copyToClipboard({
            value: address
          })
        }
      }>{shortenString(address, 5)}</UserAddress>
    </Subtitle>
    {copied && <Toast text='Copied!' />}
    <ButtonsContainer>
      <PoweredByFooterStyled />
      {defineButton()}
    </ButtonsContainer>
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(InitialScreen)