import { Button } from "components/common"
import styled from "styled-components"
import { GradientWrapper, PoweredByFooter } from 'components/pages/common'
import { Title } from 'components/common'

export const ButtonStyled = styled(Button)`
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;

  &:last-child {
    margin-bottom: 0px;
  }
`

export const PoweredByFooterStyled = styled(PoweredByFooter)`
  margin: 0 auto 16px;
`

export const GradientWrapperStyled = styled(GradientWrapper)`

  & > div {
    padding: 36px 43px;
  }
`

export const Image = styled.div`
  max-width: 240px;
  width: 100%;
`

export const ImageWithMargin = styled(Image)`
  margin-bottom: 27px;
`

export const Container = styled.div`
  max-width: 342px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100%;

  @media (min-height: 800px) {
    justify-content: center;
  }
`

export const ButtonsContainer = styled.div`
  position: fixed;
  width: 100%;
  padding: 20px 0px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 342px;

  @media (min-height: 800px) {
    position: relative;
    transform: none;
    bottom: auto;
    left: auto;
  }
`

export const ButtonsContainerWithPadding = styled(ButtonsContainer)`
  padding-bottom: 74px;
`

export const TitleComponent = styled(Title)`
  font-size: 28px;
  font-family: 'CoinbaseDisplay', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.primaryTextColor};
  margin: 0 0 9px;
`

export const Subtitle = styled.h3`
  color: ${props => props.theme.additionalTextColor};
  font-weight: 400;
  margin: 0 0 36px;
  font-size: 16px;
  text-align: center;
`
