import { FC } from 'react'
import {
  Page,
  MainContent,
  Content
} from './styled-components'
import { ThemeProvider } from 'styled-components'
import themes from 'themes'

const PageComponent: FC = ({
  children
}) => {
  const currentTheme = themes.dark
  return <ThemeProvider theme={currentTheme}>
    <Page>
      <MainContent>
        <Content>
          {children}
        </Content>
      </MainContent>
    </Page>
  </ThemeProvider>
}


export default PageComponent
