import defineNetworkName from './define-network-name'
type TDefineTokenAddressBySymbol = (
  symbol: string,
  chain: number | null
) => string

const defineTokenAddressBySymbol: TDefineTokenAddressBySymbol = (
  symbol,
  chain
) => {
  
  if (chain === 80001) {
    if (!symbol) {
      return '0x0fa8781a83e46826621b3bc094ea2a0212e71b23'
    }
    switch (symbol.toLocaleLowerCase()) {
      case 'matic':
        return '0x0000000000000000000000000000000000000000'
      case 'usdc':
      default:
        return '0x0fa8781a83e46826621b3bc094ea2a0212e71b23'
    }
  }

  if (chain === 8453) {
    if (!symbol) {
      return '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'
    }
    switch (symbol.toLocaleLowerCase()) {
      case 'eth':
        return '0x0000000000000000000000000000000000000000'
      case 'usdc':
      default:
        return '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'
    }
  }

  if (chain === 84531) {
    if (!symbol) {
      return '0xf175520c52418dfe19c8098071a252da48cd1c19'
    }
    switch (symbol.toLocaleLowerCase()) {
      case 'eth':
        return '0x0000000000000000000000000000000000000000'
      case 'usdc':
      default:
        return '0xf175520c52418dfe19c8098071a252da48cd1c19'
    }
  }

  // default is polygon
  if (!symbol) {
    return '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
  }
  switch (symbol.toLocaleLowerCase()) {
    case 'matic':
      return '0x0000000000000000000000000000000000000000'
    case 'usdc':
    default:
      return '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
  }
}

export default defineTokenAddressBySymbol