import { FC } from 'react'
import { Image, Title, Subtitle, ButtonStyled } from 'components/pages/common/styles/styled-components'
import { ButtonsContainer } from '../styles/styled-components'
import Icons from 'icons'

const ErrorComponent: FC = (props) => {
  return <>
    <Image>
      <Icons.CommonErrorIcon />
    </Image>
    <Title>Something went wrong</Title>
    <Subtitle>Please, try again</Subtitle>
    <ButtonsContainer>
      <ButtonStyled
        appearance='action'
        onClick={() => window.location.reload()}
      >
        Retry
      </ButtonStyled>
    </ButtonsContainer>
  </>
}

export default ErrorComponent