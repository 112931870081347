import { useEffect } from 'react'
import {
  useAccount,
  useNetwork
} from 'wagmi'

const useConnection = (
  initialized: boolean,
  initialize: any,
  reinitialize: any,
  urlChainId?: string | null,
  urlTokenAddress?: string,
) => {
  const { address, isConnected: active, connector } = useAccount()
  const { chain } = useNetwork()
  const connectorChainId = chain ? chain.id : null

  useEffect(() => {

    if (!active) { return }
    if (!initialized) {
      initialize(
        connector,
        urlChainId,
        urlTokenAddress,
        address,
      )
    } else {
      if (address) {
        reinitialize(
          address,
          urlTokenAddress,
          connector
        )
      }
    }
  }, [address, connector, active, urlChainId, urlTokenAddress, connectorChainId])

  return {
    address, 
    connector,
    active
  }
}

export default useConnection
