import { FC, useEffect, ReactElement } from 'react'
import { TTransferStatus } from 'types'
import { AlreadyClaimed, ErrorLinkExpired, ErrorScreen } from 'components/pages/common'
import { Loader } from 'components/common'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import * as linkAsyncActions from 'data/store/reducers/link/async-actions'
import PageConnector from '../page-connector'
import { Container } from './styled-components'

const mapStateToProps = ({
  link: { transferStatus },
  user: { initialized }
}: RootState) => ({
  transferStatus,
  initialized
})

const mapDispatcherToProps = (dispatch: IAppDispatch) => {
  return {
    getTransferStatus: (
      transferId: string,
      networkName: string,
      sender: string,
      tokenSymbol: string,
      callback: (location: string) => void
    ) => dispatch(linkAsyncActions.getTransferStatus(
      transferId,
      networkName,
      sender,
      tokenSymbol,
      callback
    ))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

type TDefineStep = (step: TTransferStatus | null) => ReactElement

const defineCurrentScreen: TDefineStep = status => {
  switch (status) {
    case 'canceled':
    case 'refunded':
      return <ErrorLinkExpired />
    case 'redeemed':
      return <AlreadyClaimed />
    case 'error':
    case 'created':
      return <ErrorScreen />
    default:
      return <Loader />
  }
}

const TransferPage: FC<ReduxType> = ({
  transferStatus,
  getTransferStatus,
  initialized
}) => {

  const {
    transfer_id,
    token_symbol,
    network_name,
    sender
  } = useParams<{
    network_name: string
    token_symbol: string
    sender: string
    transfer_id: string
  }>()

  const history = useHistory()

  useEffect(() => {
    if (!initialized) { return }
    getTransferStatus(
      transfer_id,
      network_name,
      sender,
      token_symbol,
      (location) => history.push(location)
    )
  }, [initialized])

  return <PageConnector>
    <Container>
      {defineCurrentScreen(transferStatus)}
    </Container>
  </PageConnector>
}


export default connect(mapStateToProps, mapDispatcherToProps)(TransferPage)