import styled from "styled-components"
import { Button, Title } from 'components/common'

export const Container = styled.div`
  max-width: 342px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
`

export const ButtonStyled = styled(Button)`
  width: 100%;
`

export const TitleStyled = styled(Title)`
  margin: 0 0 32px;
  align-self: flex-start;
  font-family: 'CoinbaseDisplay', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 28px;
`

export const Note = styled.div`
  margin-top: 16px;
  color: ${props => props.theme.additionalTextColor};
  font-size: 12px;
`