import { FC } from 'react'
import {
  Container,
  Image,
  Title,
  Subtitle,
} from 'components/pages/common/styles/styled-components'
import Icons from 'icons'

const ErrorScreen: FC = () => {
  return <Container>
    <Image>
      <Icons.CommonErrorIcon />
    </Image>
    <Title>Link not found</Title>
    <Subtitle>This claim link is no longer available.</Subtitle>
  </Container>
}

export default ErrorScreen
