
import { Dispatch } from 'redux'
import * as actionCreateLink from '../actions'
import * as asyncActionsToken from '../../token/async-actions'
import * as actionsToken from '../../token/actions'
import { getPrices } from '../../user/async-actions'
import { LinkCreateActions } from '../types'
import { TokenActions } from '../../token/types'
import getTokenBalance from '../../token/async-actions/get-token-balance'
import { BigNumber } from 'ethers'
import { RootState } from 'data/store'
import { alertError } from 'helpers'
import { nativeTokenAddress } from 'configs/application'

export default function getInitialData(
  tokenAmount: string
) {
  return async (
    dispatch: Dispatch<LinkCreateActions> & Dispatch<TokenActions>,
    getState: () => RootState
  ) => {
    
    try {
      dispatch(actionCreateLink.setLoading(true))
      
      const {
        user: {
          provider,
          address,
          chainId,
          sdk
        },
        linkCreate: {
          tokenAddress,
          step
        },
      } = getState()

      if (!sdk) {
        return alertError('SDK is not provided')
      }

      const tokenType = tokenAddress !== nativeTokenAddress ? 'ERC20' : 'NATIVE'
      dispatch(actionsToken.setTokenType(tokenType))

      if (
        tokenAddress
      ) {
        await getTokenBalance(
          dispatch,
          address,
          tokenAddress,
          provider
        )

        await getPrices(dispatch)
      }

      if (
        tokenAddress &&
        chainId &&
        address
      ) {
        await asyncActionsToken.getTokenData(
          tokenType,
          tokenAddress,
          chainId,
          dispatch
        )
      }

      const limits = await sdk.getLimits({
        token: tokenAddress as string,
        tokenType,
        chainId: Number(chainId)
      })

      if (limits) {
        dispatch(actionCreateLink.setMaxTransferLimit(limits.maxTransferAmount))
        dispatch(actionCreateLink.setMinTransferLimit(limits.minTransferAmount))
      }

      dispatch(actionCreateLink.setLoading(false))
      dispatch(actionCreateLink.setInitialized(true))
      if (step !== 'transaction_sign') {
        dispatch(actionCreateLink.setStep('initial'))
        if (tokenAmount && !isNaN(Number(tokenAmount))) {
          dispatch(actionCreateLink.setAmount(BigNumber.from(tokenAmount)))
        }
      }
    } catch (
      error: any
    ) {
      console.log(error, error.statusCode)
    }
  }
}
