import { ethers } from 'ethers'
import { TTokenType, TLink } from 'types'

type TCreateLinkObject = (
  claimUrl: string,
  transferId: string,
  tokenAddress: string,
  tokenAmount: ethers.BigNumber,
  decimals: number,
  tokenType: TTokenType,
  symbol: string,
  image: string
) => TLink

const createLinkObject: TCreateLinkObject = (
  claimUrl,
  transferId,
  tokenAddress,
  tokenAmount,
  decimals,
  tokenType,
  symbol,
  image
) => {
  const createDate = new Date()

  const newLink: TLink = {
    content: claimUrl,
    id: transferId,
    createdAt: String(+createDate),
    tokenAddress,
    tokenAmount: String(tokenAmount),
    tokenType,
    decimals,
    symbol,
    image: String(image)
  }
  return newLink
}

export default createLinkObject

