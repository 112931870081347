import React, { FC } from 'react'
import Icons from 'icons'
import {
  OverlayContent,
  OverlayContainer,
  OverlayHeader,
  HeaderLogo,
  CloseButton,
  OverlayTitle,
  OverlayButtons,
  ButtonStyled,
  OverlayBody,
} from './styled-components'
import TProps from './types'

const OverlayScreen: FC<TProps> = ({
  title,
  className,
  onCloseAction,
  mainAction,
  children,
  mainActionTitle = 'Ok, I understand',
  headerLogo,
}) => {
  return (
    <OverlayContainer className={className}>
      <OverlayBody>
        <OverlayHeader>
          {headerLogo && <HeaderLogo src={headerLogo} alt='Linkdrop' />}
          <CloseButton onClick={onCloseAction}>
            <Icons.CloseSquareIcon />
          </CloseButton>
        </OverlayHeader>
        <OverlayContent>
          <OverlayTitle>{title}</OverlayTitle>
          {children}
        </OverlayContent>
        {mainAction && (
          <OverlayButtons>
            <ButtonStyled appearance='action' onClick={mainAction}>
              {mainActionTitle}
            </ButtonStyled>
          </OverlayButtons>
        )}
      </OverlayBody>
    </OverlayContainer>
  )
}

export default OverlayScreen
