import styled, { css } from 'styled-components'

type TAnimation = {
  animationStart: boolean
}

export const ToastContainer = styled.div<TAnimation>`
  padding: 18px;
  position: fixed;
  background-color: ${props => props.theme.toastBackgroundColor};
  border-radius: 8px;
  margin-right: -100%;
  bottom: 2%;
  right: 50%;
  transform: translateX(50%);
  transition: all 0.7s ease-in-out;
  z-index: 11;
  max-width: 375px;
  width: calc(100vw - 40px);

  ${props => props.animationStart && css`
    margin-right: 0%;
  `}
`

export const ToastText = styled.p`
  margin: 0;
  font-size: 14px;
  color: #FFF;
`
