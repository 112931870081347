import { Dispatch } from 'redux'
import {
  LinkCreateActions,
} from '../types'
import {
  LinksActions,
} from '../../links/types'
import { alertError, defineNetworkName, createLinkObject } from 'helpers'
import { RootState } from 'data/store'
import { TCustomSigner, TLink } from 'types'
import * as actionsCreateLink from '../actions'
import { plausibleApi } from 'data/api'
import * as actionsLinks from '../../links/actions'
import randomBytes from 'randombytes'
type TDomain = {
  name: string
  version: string
  verifyingContract: string
  salt?: string
  chainId?: number
}

const sign = (
  callback?: (linkId: string) => void
) => {
  return async (
    dispatch: Dispatch<LinkCreateActions> & Dispatch<LinksActions>,
    getState: () => RootState
  ) => {

    const {
      user: {
        chainId,
        sdk
      },
      linkCreate: {
        tokenAddress,
        tokenAmount
      },
      token: {
        decimals,
        name,
        image,
        tokenType
      },
      user: {
        claimLink,
        signer
      },
      links: {
        links
      }
    } = getState()
    dispatch(actionsCreateLink.setLoading(true))
    try {
      let claimLinkToUse = claimLink
      //  || await sdk?.getLinkdrop({ token: tokenAddress, transferId: transferId as string })
      const link = await claimLinkToUse?.generateClaimUrl({
        signTypedData: (
          domain: TDomain,
          types: any,
          message: Record<string, string | number>
        ) => (signer as TCustomSigner)._signTypedData(domain, types, message),
        getRandomBytes: (length: number) => new Uint8Array(randomBytes(length)) 
      })
      // const res = await claimLinkToUse?.updateAmount('1000')
      // console.log({ res })

      if (!link) {
        throw new Error('Link was not generated')
      }
      if (!tokenAddress) {
        throw new Error('tokenAddress is not defined')
      }

      const newLink = createLinkObject(
        link.claimUrl,
        link.transferId,
        tokenAddress,
        tokenAmount,
        decimals,
        tokenType,
        name,
        String(image)
      )

      const linksUpdated = [newLink, ...links]

      plausibleApi.invokeEvent({
        eventName: 'link_generated',
        data: {
          network: defineNetworkName(chainId)
        }
      })

      dispatch(actionsLinks.setLinks(linksUpdated))

      localStorage && localStorage.setItem('links', JSON.stringify(linksUpdated))
      
      callback && callback(link.transferId)
    } catch (e) {
      const err = e as { code: string }
      if (err.code === "ACTION_REJECTED") {
        alertError('You cancelled the link creation. Please try again')
      } else {
        alertError('Some error occured, please check console for more information')
      }
      console.log({ err })
    }
    dispatch(actionsCreateLink.setLoading(false))
  }
}

export default sign

