import { FC } from 'react'
import {
  AmountFiat,
  AmountTokens,
  TokenIcon,
  NetworkIcon,
  NetworkName
} from './styled-components'
import TProps from './types'
import {
  convertTokensToFiat,
  defineNetworkName,
  convertNativeToUsd
} from 'helpers'
import PolygonLogoImage from 'images/polygon-logo.png'
import BaseLogoImage from 'images/base-logo.png'
import GradientWrapper from '../gradient-wrapper'
import { TTokenType } from 'types'

const defineNetworkLogo = (chainId: number | null) => {
  if (chainId === 8453 || chainId === 84531) {
    return BaseLogoImage
  }
  return PolygonLogoImage
}

const defineTitle = (
  amount:string,
  chainId: number | null,
  name: string,
  tokenType: TTokenType | null,
  ethPrice: string,
  maticPrice: string,
  decimals: number
) => {
  const networkName = defineNetworkName(chainId)

  if (tokenType === 'ERC20') {
    return <>of {name} ({parseFloat(amount)} {name}) on <NetworkName>{networkName}</NetworkName></>
  }
  const conversionRate = chainId === 137 || 80001 ? maticPrice : ethPrice
  const convertedAmount = convertNativeToUsd(amount, decimals, conversionRate)
  return <>${convertedAmount} of {name} on <NetworkName>{networkName}</NetworkName></> 
}

const defineAmount = (
  tokenType: TTokenType | null,
  amount: string,
  name: string,
  tokenAddress: string
) => {
  if (tokenType === 'ERC20') {
    const fiatAmount = convertTokensToFiat(amount, tokenAddress)
    return `$${parseFloat(fiatAmount)}`
  }
  return `${parseFloat(amount)} ${name}`
}

const defineIcons = (
  chainId: number | null,
  tokenType: TTokenType | null,
  image: string | null
) => {
  if ((chainId === 80001 || chainId === 137) && tokenType === 'NATIVE') {
    if (image) {
      return <TokenIcon src={image} alt='Token Image' />
    }
  }
  return <>
    {image && <TokenIcon src={image} alt='Token Image' />}
    <NetworkIcon src={defineNetworkLogo(chainId)} />
  </>
}

const AmountPreview: FC<TProps> = ({
  amount,
  image,
  tokenAddress,
  finished,
  name,
  chainId,
  tokenType,
  ethPrice,
  maticPrice,
  decimals
}) => {
  const convertedAmount = defineAmount(
    tokenType,
    amount,
    name,
    tokenAddress
  )
  return <GradientWrapper enableGradient={finished}>
    <AmountFiat>
      {convertedAmount}
    </AmountFiat>
    <AmountTokens>
      {defineTitle(
        amount,
        chainId,
        name,
        tokenType,
        ethPrice,
        maticPrice,
        decimals
      )}
    </AmountTokens>
    {defineIcons(
      chainId,
      tokenType,
      image
    )}
  </GradientWrapper>
  
} 

export default AmountPreview