import styled from 'styled-components'
import { Button, TokenImage, Title, Text } from 'components/common'
import { PoweredByFooter } from 'components/pages/common'

export const TitleComponent = styled(Title)`
  margin: 0 0 12px;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  font-family: 'CoinbaseDisplay', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  color: ${props => props.theme.primaryTextColor};
`

export const Subtitle = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  text-align: center;
  color: ${props => props.theme.additionalTextColor};
`

export const ScreenButton = styled(Button)`
  width: 100%;
  justify-content: center;
`

export const TokenImageContainer = styled.div`
  position: relative;
  padding: 12px 12px 0px;
`

export const Image = styled.div`
  max-width: 240px;
  margin-bottom: 24px;
`

export const PoweredByFooterStyled = styled(PoweredByFooter)`
  align-self: center;
  margin-bottom: 22px;
`