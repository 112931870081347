import { FC } from 'react'
import { Container } from './styled-components'
import { LogoLoader } from 'components/pages/common'


const ShortLinkPage: FC = () => {
  return <Container>
    <LogoLoader />
  </Container>
}

export default ShortLinkPage