export enum Constants {
  USER_SET_ADDRESS = 'USER_SET_ADDRESS',
  USER_SET_LOADING = 'USER_SET_LOADING',
  USER_SET_PROVIDER = 'USER_SET_PROVIDER',
  USER_SET_CHAIN_ID = 'USER_SET_CHAIN',
  USER_SET_SDK = 'USER_SET_SDK',
  USER_SET_INITIALIZED = 'USER_SET_INITIALIZED',
  USER_SET_IS_DAPP_BROWSER = 'USER_SET_IS_DAPP_BROWSER',
  USER_SET_IFRAME_PROVIDER = 'USER_SET_IFRAME_PROVIDER',
  USER_SET_USER_PROVIDER = 'USER_SET_USER_PROVIDER',
  USER_SET_HAS_CONNECTOR = 'USER_SET_HAS_CONNECTOR',
  USER_SET_ACCOUNT = 'USER_SET_ACCOUNT',
  USER_SET_SIGNER = 'USER_SET_SIGNER',
  USER_SET_CLAIM_LINK = 'USER_SET_CLAIM_LINK',
  USER_SET_ETH_PRICE = 'USER_SET_ETH_PRICE',
  USER_SET_MATIC_PRICE = 'USER_SET_MATIC_PRICE'
}