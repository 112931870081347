import { LinksState, LinksActions } from './types'
import { Constants } from './constants'
const linksFromStorage = localStorage && localStorage.getItem('links')
const links = linksFromStorage ? JSON.parse(linksFromStorage) : []

const initialState: LinksState = {
  links
}

export function linksReducer(
  state: LinksState = initialState,
  action: LinksActions
): LinksState {
    switch (action.type) {
      case Constants.LINKS_SET_LINKS:
        return { ...state, links: action.payload.links }
      case Constants.LINKS_ADD_LINK:
        return { ...state, links: [ action.payload.link,...state.links ] }
      default:
          return state
    }
}