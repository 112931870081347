import styled, { css } from 'styled-components'
import { Button } from '..'

export const Container = styled.div``

export const Title = styled.h3`
  margin: 0px;
  font-size: 12px;
  line-height: 1;
  text-align: left;
  color: ${(props) => (props.theme && props.theme.copyContainerTitleColor)};
`

export const Text = styled.div<{ hideCopyButton?: boolean }>`
  line-height: 20px;
  position: relative;
  border-radius: 8px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${(props) => (props.theme && props.theme.primaryTextColor)};
  padding: 20px 150px 20px 16px;
  border: 1px solid ${(props) => (props.theme && props.theme.primaryBorderColor)};

  ${props => props.hideCopyButton && css`
    padding: 20px 20px 20px 16px;
  `}
`

export const ButtonStyled = styled(Button)<{copied: boolean}>`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  border-radius: 40px;
  min-height: 40px;
  min-width: 100px;
  font-size: 16px;
  justify-content: center;
  color: ${props => props.theme.primaryTextColor};
  padding-left: 12px;
  padding-right: 12px;

  ${props => props.copied && css`
    background-color: ${props => props.theme.buttonCopiedColor}!important;
  `}

  svg {
    margin-left: 4px;
  }
`
