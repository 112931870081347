import React, { FC, useState } from 'react'
import { Container, Title, Text, ButtonStyled } from './styled-components'
import TProps from './types'
import { copyToClipboard } from 'helpers'
import Icons from 'icons'
import { plausibleApi } from 'data/api'
import { defineNetworkName } from 'helpers'

const CopyContainer: FC<TProps> = ({
  className,
  title,
  text,
  resetTimeout = 2000,
  chainId,
  hideCopyButton,
  page,
  onClick
}) => {
  const [copied, setCopied] = useState<boolean>(false)
  return (
    <Container className={className} onClick={() => {
      if (hideCopyButton && onClick) {
        onClick()
      }
    }}>
      <Text hideCopyButton={hideCopyButton}>
        <Title>{title}</Title>
        {text}
        {!hideCopyButton && <ButtonStyled
          size='extra-small'
          appearance='additional'
          copied={copied}
          onClick={() => {
            setCopied(true)
            window.setTimeout(() => {
              setCopied(false)
            }, resetTimeout)
            copyToClipboard({ value: text })
            if (page === 'connection_screen') { return }
            plausibleApi.invokeEvent({
              eventName: 'link_copied',
              data: {
                network: chainId ? defineNetworkName(chainId) : 'none',
              }
            })
            plausibleApi.invokeEvent({
              eventName: 'link_distributed',
              data: {
                network: chainId ? defineNetworkName(chainId) : 'none',
              }
            })
          }}
        >
          {copied ? <>Copied! <Icons.ButtonCheckmarkIcon /></> : 'Copy'}
        </ButtonStyled>}
      </Text>
    </Container>
  )
}

export default CopyContainer
