
import { Dispatch } from 'redux';
import { LinkActions } from '../types'
import * as actionsDrop from '../actions'
import * as asyncActionsLink from '.'
import axios, { AxiosError } from 'axios'
import { IAppDispatch, RootState } from 'data/store'
import { plausibleApi } from 'data/api'
import { defineNetworkName } from 'helpers'

export default function getLinkFromURL(
  linkCode: string,
  tokenSymbol: string
) {
  return async (
    dispatch: Dispatch<LinkActions> & IAppDispatch,
    getState: () => RootState

  ) => {
    const {
      user: {
        chainId
      }
    } = getState()
    dispatch(actionsDrop.setError(null))
    
    try {
      await dispatch(asyncActionsLink.getLinkByCode(
        linkCode,
        tokenSymbol
      ))
    } catch (err: any | AxiosError) {
      if (axios.isAxiosError(err)) {
        if (err.message === 'Network Error') {
          if (!window.navigator.onLine) {
            dispatch(actionsDrop.setStep('error_link_no_connection'))
          } else {
            dispatch(actionsDrop.setStep('error_link'))
          }
          plausibleApi.invokeEvent({
            eventName: 'link_data_fetch_errored',
            data: {
              error: 'network_error',
              network: defineNetworkName(chainId)
            }
          })
        } else if (err.response?.status === 404) {
          dispatch(actionsDrop.setStep('error_link_not_found'))
          plausibleApi.invokeEvent({
            eventName: 'link_data_fetch_errored',
            data: {
              error: 'error_link_not_found',
              network: defineNetworkName(chainId)
            }
          })
        } else if (err.response?.status === 500) {
          dispatch(actionsDrop.setStep('error_link'))
          plausibleApi.invokeEvent({
            eventName: 'link_data_fetch_errored',
            data: {
              error: 'server_error',
              network: defineNetworkName(chainId)
            }
          })
        }
      } else {
        if (err && err.code === "INVALID_ARGUMENT") {
          plausibleApi.invokeEvent({
            eventName: 'link_data_fetch_errored',
            data: {
              error: 'invalid_argument',
              network: defineNetworkName(chainId)
            }
          })
          return dispatch(actionsDrop.setStep('error_link_incorrect_parameter'))
        }
        plausibleApi.invokeEvent({
          eventName: 'link_data_fetch_errored',
          data: {
            error: 'some_error',
            network: defineNetworkName(chainId)
          }
        })
        dispatch(actionsDrop.setStep('error_link'))
      }      
    }
  } 
}
