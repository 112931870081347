export enum Constants {
  LINK_SET_TYPE = 'LINK_SET_TYPE',
  LINK_SET_STEP = 'LINK_SET_STEP',
  LINK_SET_LOADING = 'LINK_SET_LOADING',
  LINK_SET_CHAIN_ID = 'LINK_SET_CHAIN_ID',
  LINK_SET_TITLE = 'LINK_SET_TITLE',
  LINK_SET_CAMPAIGN_ID = 'LINK_SET_CAMPAIGN_ID',
  LINK_SET_LINKDROP_SIGNER_SIGNATURE = 'LINK_SET_LINKDROP_SIGNER_SIGNATURE',
  LINK_SET_LINKDROP_MASTER_ADDRESS = 'LINK_SET_LINKDROP_MASTER_ADDRESS',
  LINK_SET_HASH = 'LINK_SET_HASH',
  LINK_SET_LINK_KEY = 'LINK_SET_LINK_KEY',
  LINK_SET_TRANSFER_ID = 'LINK_SET_TRANSFER_ID',
  LINK_SET_EXPIRATION_TIME = 'LINK_SET_EXPIRATION_TIME',
  LINK_SET_WALLET = 'LINK_SET_WALLET',
  LINK_SET_WEI_AMOUNT = 'LINK_SET_WEI_AMOUNT',
  LINK_SET_IS_CLAIMED = 'LINK_SET_IS_CLAIMED',
  LINK_SET_THEME = 'LINK_SET_THEME',
  LINK_SET_ERROR = 'LINK_SET_ERROR',
  LINK_SET_CLAIM_CODE = 'LINK_SET_CLAIM_CODE',
  LINK_SET_LINK_ID = 'LINK_SET_LINK_ID',
  LINK_SET_INITIALIZED = 'LINK_SET_INITIALIZED',
  LINK_SET_TRANSFER_STATUS = 'LINK_SET_TRANSFER_STATUS',
  LINK_SET_FULL_LINK = 'LINK_SET_FULL_LINK'
}