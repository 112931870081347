import { action } from 'typesafe-actions'
import { Constants } from './constants'
import { TLinkCreateStep } from 'types'
import { BigNumber, BigNumberish } from 'ethers'

export function setStep( step: TLinkCreateStep) {
  return action(Constants.LINK_CREATE_SET_STEP, { step })
}

export function setLoading (loading: boolean) {
  return action(Constants.LINK_CREATE_SET_LOADING, { loading })
}

export function setInitialized (initialized: boolean) {
  return action(Constants.LINK_CREATE_SET_INITIALIZED, { initialized })
}

export function setAmount (tokenAmount: BigNumber) {
  return action(Constants.LINK_CREATE_SET_AMOUNT, { tokenAmount })
}

export function setHumanReadableAmount (humanReadableAmount: string) {
  return action(Constants.LINK_CREATE_SET_HUMAN_READABLE_AMOUNT, { humanReadableAmount })
}

export function setTokenAddress (tokenAddress: string) {
  return action(Constants.LINK_CREATE_SET_TOKEN_ADDRESS, { tokenAddress })
}

export function setTransferId (transferId: string) {
  return action(Constants.LINK_CREATE_SET_TRANSFER_ID, { transferId })
}

export function setMaxTransferLimit (value: BigNumberish) {
  return action(Constants.LINK_CREATE_SET_MAX_TRANSFER_LIMIT, { value })
}

export function setMinTransferLimit (value: BigNumberish) {
  return action(Constants.LINK_CREATE_SET_MIN_TRANSFER_LIMIT, { value })
}

