import { IMetamaskError } from 'types'
import {
  toHex,
} from 'helpers'
import chains from 'configs/chains'

async function switchNetwork (
	provider: any,
  chainId: number,
  callback?: () => void
) {
  try {
    await provider.provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: toHex(chainId) }],
    })

    callback && callback()
  } catch (err) {
      const switchError = err as IMetamaskError
      try {
        const chainObj = chains[chainId]
        if (chainObj) {
          const data = {
            chainName: chainObj.chainName,
            nativeCurrency: chainObj.nativeCurrency,
            rpcUrls: chainObj.rpcUrls,
            blockExplorerUrls: chainObj.blockExplorerUrls,
            chainId: toHex(chainId)
          }

          await provider.provider.request({
            method: 'wallet_addEthereumChain',
            params: [data],
          })

          callback && callback()
        }
      } catch (addError) {
        alert('Application cannot switch network')
        console.error({
          addError
        })
      }
  }
}

export default switchNetwork