import React, { FC } from 'react'
import {
  InputContainer,
  InputField,
  InputTitle,
  InputAdditionalText,
  InputFieldContainer,
  InputFieldContainerClass
} from './styled-components'
import { IProps } from './types'

const InputOriginalComponent: FC<IProps> = ({
  placeholder,
  title,
  disabled = false,
  type = 'text',
  onChange,
  error,
  name,
  value = '',
  className,
  refProp,
  note,
  onClick,
  onFocus,
  onBlur,
  prefix,
  pattern,
  inputMode,
  onKeyPress,
  suffix
}) => {
  return (
    <InputContainer disabled={disabled} error={error} className={className} onClick={onClick}>
      {title && <InputTitle error={error} disabled={disabled}>{title}</InputTitle>}
      <InputFieldContainer
        className={InputFieldContainerClass}
        disabled={disabled}
        error={error}
      >
        {prefix}
        <InputField
          ref={refProp}
          name={name}
          error={error}
          disabled={disabled}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          onChange={(evt) => onChange(evt.target.value)}
          type={type}
          placeholder={placeholder}
          value={value}
          pattern={pattern}
          inputMode={inputMode}
        />
        {suffix}
      </InputFieldContainer>
      {error && (
        <InputAdditionalText type='error' disabled={disabled}>
          {error}
        </InputAdditionalText>
      )}
      {note && (
        <InputAdditionalText type='note' disabled={disabled}>
          {note}
        </InputAdditionalText>
      )}
    </InputContainer>
  )
}

export default InputOriginalComponent
