import { FC, useEffect } from 'react'
import { TProps } from './types'
import { TextLink } from 'components/common'
import { PoweredByFooter } from 'components/pages/common'
import {
  Container,
  Text,
  Title,
  Image,
  ButtonStyled
} from './styled-components'
import { useLocation } from 'react-router-dom'
import {
  defineNetworkName,
  isPageForClaim
} from 'helpers'
import Icons from 'icons'
import { plausibleApi } from 'data/api'

const renderTitle = (path: string) => {
  if (isPageForClaim(path)) {
    return 'You have received tokens! Claim it with Coinbase Wallet'
  }

  return 'Send tokens to absolutely anyone through a link'
}

const renderDescription = (path: string) => {
  if (isPageForClaim(path)) {
    return 'Connect Coinbase Wallet or download it if you don’t have it yet to proceed claiming tokens.'
  }

  return 'You can now send tokens to a link that anyone an use to claim. Neither you nor the recipient will need to pay network fees!'
}

const CoinbaseConnect: FC<TProps> = ({
  activate,
  loading,
  chainId
}) => {

  const location = useLocation()
  useEffect(() => {
    if (isPageForClaim(location.pathname)) {
      plausibleApi.invokeEvent({
        eventName: 'link_opened_web',
        data: {
          network: defineNetworkName(Number(chainId))
        }
      })
    }
  }, [])

  return <Container>
    <div>
      <Title>
        {renderTitle(location.pathname)}
      </Title>
      <Text>
        {renderDescription(location.pathname)} <TextLink href='https://pay.linkdrop.io/' target='_blank'>Learn more</TextLink>
      </Text>
      <ButtonStyled
        onClick={activate}
        loading={loading}
        disabled={loading}
        appearance='action'
      >
        Connect to Coinbase Wallet to get started <Icons.NextIcon />
      </ButtonStyled>
      <PoweredByFooter />
    </div>
    <div>
      <Image>
        <Icons.DecorationImageLargeIcon />
      </Image>
    </div>
  </Container>
}

export default CoinbaseConnect
