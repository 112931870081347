import styled from 'styled-components'

export const AmountFiat = styled.h3`
  font-size: 44px;
  font-family: 'CoinbaseDisplay', 'Helvetica Neue', sans-serif;
  color: ${props => props.theme.primaryTextColor};
  text-align: center;
  margin: 0 0 7px;
  line-height: 72px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const AmountTokens = styled.h4`
  font-size: 14px;
  color: ${props => props.theme.additionalTextColor};
  text-align: center;
  margin: 0;
`

export const TokenIcon = styled.img`
  max-width: 36px;
  position: absolute;
  bottom: 18px;
  right: 18px;
  z-index: 1;
`

export const NetworkIcon = styled.img`
  max-width: 16px;
  position: absolute;
  bottom: 13px;
  right: 13px;
  z-index: 3;
`

export const NetworkName = styled.span`
  text-transform: capitalize;
`