import { FC, useEffect, useState } from 'react'
import {
  Page,
  MainContent,
  Content,
  LogoLoaderContainer
} from './styled-components'
import { ThemeProvider } from 'styled-components'
import themes from 'themes'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { TSystem } from 'types'
import { defineSystem, coinbaseDeeplink } from 'helpers'
import { useConnection } from 'hooks'
import { CoinbaseConnectDesktop, CoinbaseConnectMobile } from './components'
import { LogoLoader } from 'components/pages/common'
import { TActivate, TProps } from './types'
import {
  useAccount,
  useConnect
} from "wagmi"
import * as userAsyncActions from 'data/store/reducers/user/async-actions'

const mapStateToProps = ({
  user: { address, provider, chainId, initialized },
  link: { step }
}: RootState) => ({
  address,
  step,
  provider,
  chainId,
  initialized
})

const mapDispatcherToProps = (dispatch: IAppDispatch) => {
  return {
    initialize: (
      provider: any,
      urlChainId?: string,
      urlTokenAddress?: string,
      address?: string | null
    ) => dispatch(userAsyncActions.initialize(
      provider,
      urlChainId,
      urlTokenAddress,
      address
    )),
    reinitialize: (
      address: string,
      urlTokenAddress?: string,
      provider?: any,
    ) => dispatch(userAsyncActions.updateUserData(
      address,
      urlTokenAddress,
      provider
    )),
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const coinbaseConnectButton = (
  activate: TActivate,
  active: boolean,
  pending: boolean,
  isCoinbaseWallet: boolean,
  system: TSystem,
  chainId?: string | null
) => {

  if (system === 'desktop') {
    return <CoinbaseConnectDesktop
      activate={activate}
      chainId={chainId}
      loading={!active && pending}
    />
  }

  if ((system === 'ios' || system === 'android') && !pending && !isCoinbaseWallet) {
    return <CoinbaseConnectMobile
      chainId={chainId}
    />
  }

  return <LogoLoaderContainer>
    <LogoLoader />
  </LogoLoaderContainer>
}

const renderContent = (
  active: boolean,
  pending: boolean,
  isCoinbaseWallet: boolean,
  connect: () => void,
  system: TSystem,
  children: React.ReactNode,
  chainId?: string | null,
) => {
  if (!active) {
    return coinbaseConnectButton(
      connect, 
      active,
      pending,
      isCoinbaseWallet,
      system,
      chainId
    )
  }
  return children
}

const PageComponent: FC<ReduxType & TProps> = ({
  children,
  initialize,
  reinitialize,
  initialized,
  urlChainId,
  urlTokenAddress
}) => {
  const system = defineSystem()
  const currentTheme = themes.dark

  const { isConnected: active, isConnecting: pending } = useAccount()
  const isCoinbaseWallet = Boolean(window.ethereum && window.ethereum.isCoinbaseWallet)
  const { connect, connectors } = useConnect({
    onError(error) {
      console.log('Some error occured while connecting to Coinbase connector')
    }
  })

  useEffect(() => {
    const init = async () => {
      if (system !== 'desktop') {
        if (isCoinbaseWallet) {
          connect({ connector: connectors[0] })
        }
      }
    }
    init()
  }, [])

  useConnection(
    initialized,
    initialize,
    reinitialize,
    urlChainId,
    urlTokenAddress
  )

  return <ThemeProvider theme={currentTheme}>
    <Page>
      <MainContent>
        <Content>
          {renderContent(
            active,
            pending,
            isCoinbaseWallet,
            () => {
              connect({ connector: connectors[0] })
            },
            system,
            children,
            urlChainId
          )}
        </Content>
      </MainContent>
    </Page>
  </ThemeProvider>
}


export default connect(mapStateToProps, mapDispatcherToProps)(PageComponent)
