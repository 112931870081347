import { LinkCreateState, LinkCreateActions } from './types'
import { Constants } from './constants'
import { BigNumber } from 'ethers'

const initialState: LinkCreateState = {
  loading: false,
  step: 'initial',
  initialized: false,
  tokenAddress: null,
  tokenAmount: BigNumber.from(0),
  humanReadableAmount: '0',
  transferId: null,
  maxTransferLimit: BigNumber.from(0),
  minTransferLimit: BigNumber.from(0)
}

export function linkCreateReducer(
  state: LinkCreateState = initialState,
  action: LinkCreateActions
): LinkCreateState {
    switch (action.type) {
      case Constants.LINK_CREATE_SET_LOADING:
        return {...state, loading: action.payload.loading }
      case Constants.LINK_CREATE_SET_STEP:
        return {...state, step: action.payload.step }
      case Constants.LINK_CREATE_SET_INITIALIZED:
        return {...state, initialized: action.payload.initialized }
      case Constants.LINK_CREATE_SET_AMOUNT:
        return {...state, tokenAmount: action.payload.tokenAmount }
      case Constants.LINK_CREATE_SET_HUMAN_READABLE_AMOUNT:
        return {...state, humanReadableAmount: action.payload.humanReadableAmount }
      case Constants.LINK_CREATE_SET_TRANSFER_ID:
        return {...state, transferId: action.payload.transferId }
      case Constants.LINK_CREATE_SET_TOKEN_ADDRESS:
        return {...state, tokenAddress: action.payload.tokenAddress }
      case Constants.LINK_CREATE_SET_MAX_TRANSFER_LIMIT:
        return {...state, maxTransferLimit: action.payload.value }
      case Constants.LINK_CREATE_SET_MIN_TRANSFER_LIMIT:
        return {...state, minTransferLimit: action.payload.value }
      default:
          return state;
    }
}