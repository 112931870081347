import { FC } from 'react'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import {
  defineExplorerURL,
} from 'helpers'
import { 
  TitleComponent,
  ScreenButton,
  Subtitle,
  Image,
  PoweredByFooterStyled
} from './styled-components'
import { ButtonsContainer } from '../styles/styled-components'
import Icons from 'icons'

const mapStateToProps = ({
  link: {
    hash,
    type
  },
  user: {
    chainId
  },
  token: {
    image,
    name,
    decimals,
    amount
  }
}: RootState) => ({
  image,
  name,
  chainId,
  hash,
  type,
  decimals,
  amount
})

type ReduxType = ReturnType<typeof mapStateToProps>

const AlreadyClaimed: FC<ReduxType> = ({
  chainId,
  hash,
  name
}) => {

  const explorerUrl = chainId && hash ? <ScreenButton
    onClick={() => {
      window.open(`${defineExplorerURL(chainId)}/tx/${hash}`, '_blank')
    }}
    title='View in Explorer'
    appearance='default'
  /> : null

  return <>
    <Image>
      <Icons.AlreadyClaimedErrorIcon />
    </Image>
    <TitleComponent>
      ${name} already claimed
    </TitleComponent>
    <Subtitle>
      Someone has already claimed this ${name}, If this was you, you should find the ${name} in your wallet.
    </Subtitle>
    <ButtonsContainer>
      <PoweredByFooterStyled />
      {explorerUrl}
    </ButtonsContainer>
  </>
}

export default connect(mapStateToProps)(AlreadyClaimed)