import { ethers } from "ethers"

type TConvertNativeToUsd = (
  value: string,
  decimals: number,
  nativePrice: string
) => string

const convertNativeToUsd: TConvertNativeToUsd = (
  value,
  decimals,
  nativePrice
) => {
  const result = String(Number(value) * Number(nativePrice))
  if (result.length > 7) {
    return result.slice(0, 7)
  }
  return result
}

export default convertNativeToUsd