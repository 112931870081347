import { FC } from 'react'
import { WidgetStyled, WrapperContainer } from './styled-components'
import TProps from './types'

const GradientWrapper: FC<TProps> = ({
  enableGradient,
  children,
  className
}) => {
  if (enableGradient) { 
    return <WrapperContainer className={className}>
      <WidgetStyled enableGradient={enableGradient}>
        {children}
      </WidgetStyled>
    </WrapperContainer>
  }
  return <WidgetStyled className={className}>
    {children}
  </WidgetStyled>
}

export default GradientWrapper