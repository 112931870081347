import { ethers } from 'ethers'
import { LinkdropP2P, ClaimLink } from 'linkdrop-p2p-sdk'

type TCheckRedeemTxHash = (
  claimLink: ClaimLink
) => Promise<boolean | {amount: number}>

const checkRedeemTxHash: TCheckRedeemTxHash = (claimLink) => {
  return new Promise((resolve, reject) => {
    (async () => {
      const interval = window.setInterval(async () => {
        if (claimLink) {
          const statusData = await claimLink.getStatus()
          if (statusData) {
            const { status } = statusData
            if (status === 'redeemed') {
              resolve(true)
              clearInterval(interval)
            }
          }
        }
      }, 1000)
    })()
  })
}

export default checkRedeemTxHash