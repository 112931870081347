import styled from 'styled-components'
import { Button, Title } from 'components/common'
import { PoweredByFooter } from 'components/pages/common'

export const TitleComponent = styled(Title)`
  margin-bottom: 20px;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 20px;
`

export const Subtitle = styled.h3`
  font-size: 16px;
  line-height: 23px;
  margin: 0 0 30px;
  text-align: center;
  color: ${props => props.theme.additionalTextColor};
  font-weight: 400;
`

export const Container = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ScreenButton = styled(Button)`
  width: 100%;
  margin-bottom: 16px;
  justify-content: center;
`

export const UserAddress = styled.span`
  color: ${props => props.theme.linkColor};
`

export const DoneIconContainer = styled.div`
  max-width: 130px;
  margin-bottom: 50px;

  svg {
    width: 100%;
  }
`

export const PoweredByFooterStyled = styled(PoweredByFooter)`
  align-self: center;
  margin-bottom: 22px;
`