import { FC, useState, useEffect, useRef } from 'react'
import { InputStyled, Container, Image, TitleStyled, TextStyled } from './styled-components'
import { ButtonStyled } from '../styled-components'
import * as linkCreateAsyncActions from 'data/store/reducers/link-create/async-actions'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { ethers } from 'ethers'
import {
  convertFiatToTokens,
  defineNetworkName,
  defineRealNetworkName,
  convertNativeToUsd,
  defineAmountForText
} from 'helpers'
import { TextLink } from 'components/common'
import Icons from 'icons'
import { plausibleApi } from 'data/api'
import { useHistory } from 'react-router-dom'

const mapStateToProps = ({
  linkCreate: {
    step,
    initialized,
    tokenAddress,
    loading,
    tokenAmount,
    maxTransferLimit,
    minTransferLimit
  },
  user: {
    address,
    chainId,
    ethPrice,
    maticPrice
  },
  token: { balance, decimals, name, tokenType }
}: RootState) => ({
  step,
  address,
  initialized,
  tokenAddress,
  chainId,
  tokenType,
  loading,
  balance,
  decimals,
  name,
  tokenAmount,
  maxTransferLimit,
  minTransferLimit,
  ethPrice,
  maticPrice
})

const mapDispatcherToProps = (dispatch: IAppDispatch) => {
  return {
    setTokenAmount: (
      amount: string,
      callback?: (linkId: string) => void
    ) => dispatch(linkCreateAsyncActions.setTokenAmount(amount, callback))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const InitialScreen: FC<ReduxType> = ({
  setTokenAmount,
  loading,
  balance,
  decimals,
  name,
  address,
  chainId,
  tokenAmount,
  tokenAddress,
  minTransferLimit,
  maxTransferLimit,
  ethPrice,
  maticPrice,
  tokenType
}) => {
  const history = useHistory()
  const userLimit = ethers.utils.formatUnits(String(balance), decimals)
  const [ value, setValue ] = useState<string>(`0`)
  const [ started, setStarted ] = useState<boolean>(false)

  useEffect(() => {
    if (value === '0' || started || value === '') {
      return 
    }
    setStarted(true)
  }, [value])

  // const [ nativeTypeConversion, setNativeTypeConversion ] = useState<boolean>(false)
  // const nativePrice = chainId === 80001 || chainId === 137 ? maticPrice : ethPrice
  // const convertedPrice = nativeTypeConversion ? convertNativeToUsd(value, nativePrice) : convertUsdToNative(value, nativePrice) 

  // const tokensToShare = `${convertValueToAmount(
  //   (value || '0'),
  //   nativeTypeConversion,
  //   nativePrice,
  //   tokenType
  // )}`

  const nativePrice = (chainId === 80001 || chainId === 137) ? maticPrice : ethPrice
  const tokensToShare = `${convertFiatToTokens((value || '0'), tokenAddress as string)}`

  const userLimitFailed = ethers.utils.parseUnits(tokensToShare, decimals).gt(ethers.utils.parseUnits(userLimit, decimals))
  const linkFundsMaxLimit = maxTransferLimit.toString()
  const linkFundsMinLimit = minTransferLimit.toString()
  const linkFundsMaxLimitParsed = ethers.utils.formatUnits(linkFundsMaxLimit, decimals)
  const linkFundsMinLimitParsed = ethers.utils.formatUnits(linkFundsMinLimit, decimals)

  const applicationLimitMaxFailed = ethers.utils.parseUnits(tokensToShare, decimals).gt(linkFundsMaxLimit)

  const applicationLimitMinFailed = ethers.utils.parseUnits(tokensToShare, decimals).lt(linkFundsMinLimit)

  const networkName = defineRealNetworkName(chainId)
  const notConnected = !address || !chainId
  const ref = useRef(null)

  const transactionCallback = (linkId: string) => {
    history.push(`/links/${linkId}`)
  }

  useEffect(() => {
    plausibleApi.invokeEvent({
      eventName: 'sender_flow_started',
      data: {
        network: defineNetworkName(chainId)
      }
    })
  }, [])

  const defineInputNote = () => {
    const symbol = tokenType === 'NATIVE' ? name : '$'

    if (notConnected) {
      return `Error occured: Coinbase wallet is not connected`
    }

    if (loading) {
      return `Loading...`
    }
    
    if (applicationLimitMaxFailed) {
      return `Max amount ${defineAmountForText(symbol, linkFundsMaxLimitParsed)}`
    }
    if (applicationLimitMinFailed) {
      return `Amount should be more than or equal to ${defineAmountForText(symbol, linkFundsMinLimitParsed)}`
    }
    if (userLimitFailed) {
      return `You do not have enough ${name} on ${networkName}. Only ${defineAmountForText(symbol, userLimit)} available.`
    }
    return defineConversionValue()
    
  }

  const defineConversionValue = () => {
    if (loading) {
      return `Loading...`
    }
    const conversionSymbol = tokenType === 'NATIVE' ? '$' : name
    const finalAmount = tokenType === 'NATIVE' ? convertNativeToUsd(value, decimals, nativePrice) : tokensToShare
    return defineAmountForText(conversionSymbol, finalAmount)
  }

  useEffect(() => {
    if (!tokenAmount) { return }
    const amountToCreateLink = parseFloat(ethers.utils.formatUnits(tokenAmount, decimals))
    setValue(`${amountToCreateLink}`)
  }, [tokenAmount])

  return <Container>
    <Image>
      <Icons.DecorationImageIcon />
    </Image>
    <TitleStyled>
      Send {name} to a link that anyone can use to claim
    </TitleStyled>
    <TextStyled>
      Funds will be withdrawn and can be claimed by a user with the link we’ll provide. Neither you nor the recipient will need to pay network fees! Funds not claimed in 30 days will be automatically returned. <TextLink href='https://pay.linkdrop.io/' target='_blank'>Learn more</TextLink>
    </TextStyled>
    <InputStyled
      prefix={tokenType === 'ERC20' ? '$' : undefined}
      refProp={ref}
      suffix={tokenType === 'ERC20' ? undefined : name}
      title='Enter amount'
      value={value}
      type="text"
      pattern="\d*(.,d+)?"
      inputMode="decimal"
      onFocus={() => {
        if (value === '0') {
          setValue('')
        }
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          const refInput = ref.current !== null ? ref.current as HTMLInputElement : null
          if (refInput) {
            refInput.blur()
          }
        }
      }}
      onBlur={() => {
        if (value === '') {
          setValue('0')
        }
      }}
      disabled={loading || notConnected}
      onChange={(value) => {
        let valueDelimeters = value.match(/\.|\,/g);
        let hasTwoDelimeters = valueDelimeters !== null && valueDelimeters.length == 2
        console.log(hasTwoDelimeters)
        if (value.length > 7) {
          return value
        }
        if (hasTwoDelimeters) {
          return value
        } else if (value === '.' || value === ',') {
          setValue(`0`)
        } else if (/^[0-9.,]+$/.test(value) || value === '') {
          setValue(value.replace(',', '.'))
        } else if (!value) {
          setValue(`0`)
        } else if (value === '') {
          setValue(`0`)
        }
        return value
      }}
      error={started && (userLimitFailed || applicationLimitMinFailed || applicationLimitMaxFailed) ? defineInputNote() : undefined}
      note={!started ? defineConversionValue() : (!userLimitFailed && !applicationLimitMinFailed && !applicationLimitMaxFailed ? defineInputNote() : undefined)}
    />
    <ButtonStyled
      appearance='action'
      loading={loading}
      disabled={
        loading ||
        userLimitFailed ||
        Number(value) === 0 ||
        applicationLimitMinFailed || 
        applicationLimitMaxFailed
      }
      onClick={() => {
        setTokenAmount(
          tokensToShare,
          transactionCallback
        )
      }}
    >
      {loading ? 'Loading' : <>Continue<Icons.NextIcon /></>}
    </ButtonStyled>
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(InitialScreen)