import styled, { css } from 'styled-components'
import { TProps } from './types'

export const Note = styled.div<TProps>`
  background: ${(props) => props.theme.additionalNoteBgColor};
  color: ${(props) => props.theme.additionalNoteTextColor};
  padding: 16px;
  border-radius: 8px;
  display: flex;
  font-size: 14px;
  align-items: center;
  box-sizing: border-box;

  ${(props) =>
    props.type === 'attention' &&
    css`
      background: ${(props.theme && props.theme.noteAttentionBgColor)};
      color: ${(props.theme && props.theme.additionalNoteTextColor)};
    `}

  ${(props) =>
    props.position === 'bottom' &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      border-radius: 0;
      width: 100%;
    `}

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      user-select: none;
    `}
`

export const NoteIcon = styled.div`
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NoteText = styled.p`
  margin: 0;
`
