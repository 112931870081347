import defineNetworkName from './define-network-name'
type TDefineExplorerURL = (chainId: number) => string

const defineExplorerUrl: TDefineExplorerURL = (chainId) => {
  if (Number(chainId) === 8453) { return 'https://basescan.org' }
  if (Number(chainId) === 137) { return 'https://polygonscan.com' }
  if (Number(chainId) === 80001) { return 'https://mumbai.polygonscan.com' }
  if (Number(chainId) === 84531) { return 'https://goerli.basescan.org/' }
  const networkName = defineNetworkName(chainId)
  return `https://${networkName}.etherscan.io`
}

export default defineExplorerUrl