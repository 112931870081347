import defineNetworkName from './define-network-name'
type TDefineTokenTypeBySymbol = (
  symbol: string,
) => string

const defineTokenTypeBySymbol: TDefineTokenTypeBySymbol = (
  symbol
) => {
    switch (symbol.toLowerCase()) {
    case 'matic':
    case 'eth':
      return 'NATIVE'
    case 'usdc':
    default:
      return 'ERC20'
  }
}

export default defineTokenTypeBySymbol