import { ReactComponent as EthereumLogo } from './ethereum-logo.svg';
import { ReactComponent as DroplistLogo } from './droplist-logo.svg';
import { ReactComponent as DashboardMenuIcon } from './dashboard-menu-icon.svg';
import { ReactComponent as SupportMenuIcon } from './support-menu-icon.svg';
import { ReactComponent as FaqMenuIcon } from './faq-menu-icon.svg';
import { ReactComponent as DocsMenuIcon } from './docs-menu-icon.svg';
import { ReactComponent as StarMenuIcon } from './star-menu-icon.svg';
import { ReactComponent as BlueArrowIcon } from './blue-arrow-icon.svg';
import { ReactComponent as ArrowLeftIcon } from './arrow-left-icon.svg';
import { ReactComponent as DoneIcon } from './done-icon.svg';
import { ReactComponent as ExclamationIcon } from './exclamation-icon.svg';
import { ReactComponent as SecureIcon } from './secure-icon.svg';
import { ReactComponent as OrangeExclamationIcon } from './orange-exclamation-icon.svg';
import { ReactComponent as WarningIcon } from './warning-icon.svg';
import { ReactComponent as LockIcon } from './lock-icon.svg';
import { ReactComponent as CloseIcon } from './close-icon.svg';
import { ReactComponent as PolygonIcon } from './polygon-matic-icon.svg';
import { ReactComponent as EthereumIcon } from './ethereum-icon.svg';
import { ReactComponent as LoaderIcon } from './loader-icon.svg';
import { ReactComponent as ExclamationFilledIcon } from './exclamation-filled-icon.svg'
import { ReactComponent as LinkdropIcon } from './linkdrop-icon.svg'
import { ReactComponent as RefreshIcon } from './refresh-icon.svg'
import { ReactComponent as ActionDoneIcon } from './action-done-icon.svg'
import { ReactComponent as ButtonCheckmarkIcon } from './button-checkmark.svg'
import { ReactComponent as NextIcon } from './next-icon.svg'
import { ReactComponent as DecorationImageIcon } from './decoration-image.svg'
import { ReactComponent as DecorationImageLargeIcon } from './decoration-image-large.svg'
import { ReactComponent as ExpiredErrorIcon } from './expired-error.svg'
import { ReactComponent as AlreadyClaimedErrorIcon } from './already-claimed-error.svg'
import { ReactComponent as CommonErrorIcon } from './common-error.svg'
import { ReactComponent as FinishedIcon } from './finished-icon.svg'
import { ReactComponent as ReceivedIcon } from './received-icon.svg'
import { ReactComponent as ReceivedUSDCIcon } from './received-usdc-icon.svg'
import { ReactComponent as CloseSquareIcon } from './close-square-icon.svg'

const Icons = {
  EthereumLogo,
  ReceivedUSDCIcon,
  CloseSquareIcon,
  ReceivedIcon,
  DecorationImageLargeIcon,
  DecorationImageIcon,
  ExpiredErrorIcon,
  AlreadyClaimedErrorIcon,
  FinishedIcon,
  CommonErrorIcon,
  ActionDoneIcon,
  NextIcon,
  ButtonCheckmarkIcon,
  DroplistLogo,
  RefreshIcon,
  LinkdropIcon,
  DashboardMenuIcon,
  ExclamationFilledIcon,
  SupportMenuIcon,
  PolygonIcon,
  FaqMenuIcon,
  DocsMenuIcon,
  StarMenuIcon,
  BlueArrowIcon,
  ArrowLeftIcon,
  DoneIcon,
  ExclamationIcon,
  SecureIcon,
  OrangeExclamationIcon,
  WarningIcon,
  LockIcon,
  CloseIcon,
  EthereumIcon,
  LoaderIcon
}

export default Icons
