import { Input, Title, Text } from 'components/common'
import styled from 'styled-components'

export const InputStyled = styled(Input)`
  margin-bottom: 24px;
  width: 100%;
`

export const Container = styled.div`
  margin-bottom: 22px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Image = styled.div`
  max-width: 240px;
  margin-bottom: 24px;
  width: 100%;
`

export const TitleStyled = styled(Title)`
  margin: 0 0 16px;
  font-family: 'CoinbaseDisplay', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  align-self: flex-start; 
`

export const TextStyled = styled(Text)`
  color: ${props => props.theme.additionalTextColor};
  font-size: 16px;
  margin: 0 0 24px;
  font-weight: 400;
`
