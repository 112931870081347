import { FC } from 'react'
import { ButtonStyled } from '../styled-components'
import { Title, Container, AwaitActionsStyled, TextStyled } from './styled-components'
import { RootState, IAppDispatch } from 'data/store'
import * as linkCreateAsyncActions from 'data/store/reducers/link-create/async-actions'
import { connect } from 'react-redux'
import { TLinkCreateStep } from 'types'
import { ethers } from 'ethers'
import { useHistory } from 'react-router-dom'
import { AwaitAction } from 'components/common/await-actions/types'
import { AmountPreview } from 'components/pages/common'

type TGetActionItems = (
  loading: boolean,
  step: TLinkCreateStep
) => AwaitAction[]

const actionItems: TGetActionItems = (loading, step) => {
  if (step === 'transaction_sign') {
    return [
      { title: 'Withdraw crypto', status: 'done' },
      { title: 'Sign message to generate link', status: loading ? 'loading' : 'upcoming' }
    ]
  }
  return [
    { title: 'Withdraw crypto', status: loading ? 'loading' : 'upcoming' },
    { title: 'Sign message to generate link', status: 'upcoming' }
  ]
}

const mapStateToProps = ({
  linkCreate: { step, tokenAmount, loading, tokenAddress },
  token: { decimals, image, name, tokenType },
  user: {
    chainId,
    ethPrice,
    maticPrice
  }
}: RootState) => ({
  step,
  tokenAmount,
  decimals,
  tokenType,
  image,
  loading,
  tokenAddress,
  name,
  chainId,
  ethPrice,
  maticPrice
})

const mapDispatcherToProps = (dispatch: IAppDispatch) => {
  return {
    secure: (callback?: (linkId: string) => void) => dispatch(linkCreateAsyncActions.secure(callback)),
    sign: (callback?: (linkId: string) => void) => dispatch(linkCreateAsyncActions.sign(callback))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const defineButton = (
  step: TLinkCreateStep,
  loading: boolean,
  secure: (callback?: () => void) => void,
  sign?: () => void
) => {
  if (step === 'transaction_secure') {
    return <ButtonStyled
      appearance='action'
      loading={loading}
      disabled={loading}
      onClick={() => secure()}
    >
      Confirm
    </ButtonStyled>
  } else {
    return <ButtonStyled
      loading={loading}
      appearance='action'
      onClick={sign}
      disabled={loading}
    >
      Confirm
    </ButtonStyled>
  }
}

const renderTitle = (
  step: TLinkCreateStep
) => {
  return <Title>Create your claim link</Title> 
}

const TransactionScreen: FC<ReduxType> = ({
  tokenAmount,
  step,
  decimals,
  loading,
  sign,
  secure,
  image,
  tokenAddress,
  name,
  chainId,
  tokenType,
  ethPrice,
  maticPrice
}) => {
  const amount = ethers.utils.formatUnits(String(tokenAmount), decimals)
  const history = useHistory()
  const transactionCallback = (linkId: string) => {
    history.push(`/links/${linkId}`)
  }

  return <Container>
    <AmountPreview
      tokenType={tokenType}
      image={image}
      tokenAddress={tokenAddress as string}
      amount={amount}
      decimals={decimals}
      name={name}
      chainId={chainId}
      ethPrice={ethPrice}
      maticPrice={maticPrice}
    />
    {renderTitle(step)}
    <TextStyled>
      Tap "Confirm" to withdraw the funds from your wallet. On the next screen you'll be provided with a shareable link that lets anyone claim the funds.
    </TextStyled>
    {/* <AwaitActionsStyled items={actionItems(loading, step)}/> */}
    {defineButton(
      step,
      loading,
      () => secure(transactionCallback),
      () => sign(transactionCallback)
    )}
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(TransactionScreen)