import { Dispatch } from 'redux';
import { LinkActions } from '../data/store/reducers/link/types'
import { UserActions } from '../data/store/reducers/user/types'
import * as linkActions from '../data/store/reducers/link/actions'
import axios, { AxiosError } from 'axios'
import { plausibleApi } from 'data/api'
import { defineNetworkName } from 'helpers'

const handleError = (
  dispatch: Dispatch<LinkActions> & Dispatch<UserActions>,
  error: any | AxiosError,
  chainId: number
) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 422) {
      dispatch(linkActions.setStep('error_already_claimed'))
    } else {
      plausibleApi.invokeEvent({
        eventName: 'claiming_errored',
        data: {
          error: 'server_error',
          network: defineNetworkName(chainId)
        }
      })
      dispatch(linkActions.setStep('error_server_fail'))
    }
    
  } else {
    if(!window.navigator.onLine) {
      dispatch(linkActions.setStep('error_no_connection'))
    } else {plausibleApi.invokeEvent({
        eventName: 'claiming_errored',
        data: {
          error: 'some_error',
          network: defineNetworkName(chainId),
        }
      })
      plausibleApi.invokeEvent({
        eventName: 'claiming_errored',
        data: {
          error: 'some_error',
          network: defineNetworkName(chainId),
        }
      })
      dispatch(linkActions.setStep('error'))
    }
  }
}

export default handleError