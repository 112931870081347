type TConvertTokensToFiat = (
  value: string,
  tokenAddress: string
) => string

const convertTokensToFiat: TConvertTokensToFiat = (
  value,
  tokenAddress
) => {
  if (!value || value === '0') {
    return '0'
  }
  if (tokenAddress === '0x0FA8781a83E46826621b3BC094Ea2A0212e71B23') {
    return value
  }
  return value
}

export default convertTokensToFiat