
type TApplicationParams = {
  highlightColor: string
  primaryTextColor: string
  logo: string
  backgroundColor: string
}

const applicationParams: TApplicationParams = {
  highlightColor: '#588AF5',
  primaryTextColor: '#FFFFFF',
  logo: '',
  backgroundColor: '#0A0B0D'
}

export const nativeTokenAddress = '0x0000000000000000000000000000000000000000'

export default applicationParams