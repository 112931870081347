import { createBrowserHistory } from 'history'
import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware, { ThunkDispatch, ThunkMiddleware } from "redux-thunk"
import { userReducer } from './reducers/user/reducer';
import { tokenReducer } from './reducers/token/reducer'
import { linkReducer } from './reducers/link/reducer'
import { linksReducer } from './reducers/links/reducer'
import { linkCreateReducer } from './reducers/link-create/reducer'
import { UserState } from './reducers/user/types';
import { LinkCreateState } from './reducers/link-create/types';
import { TokenState } from './reducers/token/types';
import { LinksState } from './reducers/links/types';
import { LinkState } from './reducers/link/types';
import { TokenActions } from './reducers/token/types'
import { LinkActions } from './reducers/link/types'
import { UserActions } from './reducers/user/types'

export const history = createBrowserHistory()
type TActions = TokenActions & LinkActions & UserActions

const reducers = combineReducers({
  user: userReducer,
  link: linkReducer,
  token: tokenReducer,
  linkCreate: linkCreateReducer,
  links: linksReducer
})

type IAppState = ReturnType<typeof reducers>

export interface RootState {
  user: UserState,
  token: TokenState,
  link: LinkState,
  linkCreate: LinkCreateState,
  links: LinksState
}

export type IAppDispatch = ThunkDispatch<IAppState, any, TActions>

const composeEnhancers =
    process.env.NODE_ENV === "development"
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
        : compose;

const store = createStore<RootState, any, any, any>(
  reducers,
  composeEnhancers(
    applyMiddleware<IAppDispatch, any>(
      thunkMiddleware as ThunkMiddleware<IAppState, TActions, any>,
    )
  )
)


export default store;