import { FC } from 'react'
import {
  PoweredBy,
  PoweredByImage
} from './styled-components'
import LinkdropLogo from 'images/linkdrop-footer.png'
import TProps from './types'

const PoweredByFooter: FC<TProps> = ({
  className
}) => {
  return <PoweredBy href='https://pay.linkdrop.io/' target='_blank' className={className}>
    Powered by
    <PoweredByImage src={LinkdropLogo} alt="Linkdrop Logo"/>
  </PoweredBy>
}


export default PoweredByFooter