import { FC } from 'react'
import { TProps, AwaitActionStatus } from './types'
import { Container, AwaitAction, AwaitActionStatusItem } from './styled-components'
import { LoadSpinner } from '../index'
import Icons from 'icons'

const defineStatus = (status: AwaitActionStatus) => {
  switch (status) {
    case 'loading':
      return <AwaitActionStatusItem status='loading'>
        <LoadSpinner size='large' />
      </AwaitActionStatusItem>
    case 'done':
      return <AwaitActionStatusItem status='done'>
        <Icons.ActionDoneIcon />
      </AwaitActionStatusItem>
    case 'upcoming':
      return <AwaitActionStatusItem status='upcoming' />
  }
}


const AwaitActions: FC<TProps> = ({
  items, className
}) => {
  return <Container className={className}>
    {items.map(item => <AwaitAction>
      {defineStatus(item.status)}
      {item.title}
    </AwaitAction>)}
  </Container>
}

export default AwaitActions