import { AwaitActions } from 'components/common'
import styled from 'styled-components'
import { TitleStyled } from '../styled-components'
import { Text } from 'components/common'

export const Title = styled(TitleStyled)`
  margin-bottom: 4px;
`

export const AwaitActionsStyled = styled(AwaitActions)`
  margin-bottom: 20px;
`

export const Container = styled.div`
  margin-bottom: 22px;
  width: 100%;
`

export const TextStyled = styled(Text)`
  color: ${props => props.theme.additionalTextColor};
  font-size: 16px;
  margin: 0 0 24px;
  font-weight: 400;
`