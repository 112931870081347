import { FC } from 'react'
import { Image, Title, Subtitle, ButtonStyled } from 'components/pages/common/styles/styled-components'
import Icons from 'icons'

const ErrorComponent: FC = () => {
  return <>
    <Image>
      <Icons.ExpiredErrorIcon />
    </Image>
    <Title>Link expired</Title>
    <Subtitle>This claim link is no longer available.</Subtitle>
  </>
}

export default ErrorComponent