import { FC } from 'react'
import {
  OverlayParagraph,
  OverlaySubtitle,
  OverlayListItem,
  OverlayList
} from 'components/common'
import { CopyContainerStyled } from './styled-components'

const PopupContents: FC = () => {
  return <>
    <OverlaySubtitle>Install and set up the wallet</OverlaySubtitle>
    <OverlayParagraph>
      To claim tokens you need to download Coinbase Wallet, and set it up, following the instructions inside the wallet app.
    </OverlayParagraph>
    <OverlaySubtitle>“Connect Coinbase Wallet” button doesn’t work</OverlaySubtitle>
    <OverlayParagraph>
      Sometimes deep links do not work on particular devices. If that happens, please:
    </OverlayParagraph>
    <OverlayList>
      <OverlayListItem>
        1. Copy the claim link
        <CopyContainerStyled
          page='connection_screen'
          title='Claim link'
          text={window.location.href}
        />
      </OverlayListItem>
      <OverlayListItem>2. Open the dApp browser in Coinbase Wallet</OverlayListItem>
      <OverlayListItem>3. Paste the link in the dApp browser and load the page to proceed</OverlayListItem>
    </OverlayList>
  </>
}

export default PopupContents