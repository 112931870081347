import { action } from 'typesafe-actions'
import { Constants } from './constants'
import { TLink } from 'types'

export function setLinks(links: TLink[]) {
  return action(Constants.LINKS_SET_LINKS, { links })
}

export function addLink(link: TLink) {
  return action(Constants.LINKS_ADD_LINK, { link })
}
